/**/
import React, { Component, Fragment } from 'react';
import s from './Login.module.css'
import Container from "reactstrap/es/Container";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getUser} from "../../redux/user";
import {setLanguage} from "../../redux/language";
import {Input, Modal} from "reactstrap";
import ModalBody from "reactstrap/es/ModalBody";
import userIcon from "../../assets/loginIcon.svg";
import lockIcon from "../../assets/passwordIcon.svg";
import eyeOpenIcon from "../../assets/exposedPassword.svg";
import eyeClosedIcon from "../../assets/passwordHidden.svg";
import t, {getLanguage, getSourceLanguage, LANG_STORAGE_KEY, LANGCODE_STORAGE_KEY} from "../../translation/translation";
import * as classnames from "classnames";
import auth from "../../api/auth";
import YellowButton from "../../components/ThemeButton/YellowButton";
import AppLoader from "../../components/AppLoader/AppLoader";
import logo from "../../assets/primaryLogo.svg";
import {getSafeDeep} from "../../utils/state";
import {withRouter} from "react-router";
import {supportedLanguages} from "../../translation/language";
import HoveringInputField from "../../components/HoveringInputField/HoveringInputField";
import Footer from "../../components/Footer/Footer";
import {getRevertPermission} from "../../redux/revertPermission";

class Login extends Component {

	constructor(props) {
		super(props);

		this.state = {
			username: '',
			password: '',
			isLoading: false,
			passwordIsExposed: false,
			errors: {}
		}
	}

	async componentDidMount() {
		const language = getSafeDeep(this.props.match, "params.lang", "en");
		localStorage.setItem(LANG_STORAGE_KEY, JSON.stringify({ language: supportedLanguages[language] }));
		localStorage.setItem(LANGCODE_STORAGE_KEY, supportedLanguages[language].code);
		await this.props.setLanguage(language);
		this.setState({ state: this.state });

	}

	validateForm () {
		return this.state.username && this.state.password;
	};

	getFormErrors () {
		const errors = {};
		if (!this.state.username) errors.username = t("LOGIN.usernameEmptyError");
		if (!this.state.password) errors.password = t("LOGIN.passwordEmptyError");
		return errors;
	}

	handleSubmit = () => async (e) => {
		e.preventDefault();
		const {username, password} = this.state;
		try {
			if (!this.validateForm()) throw { type: "EMPTY_LOGIN", errors: this.getFormErrors()};
			this.setState({ isLoading: true });
			const {data} = await auth.login(username, password);
			this.props.getRevertPermission();
			await this.props.getUser();
			this.setState({ isLoading: false});
		} catch (e) {
			let errors = {};
			if (e.type === "EMPTY_LOGIN") {
				errors = {...e.errors};
			} else {
				errors = { username: t("LOGIN.invalidCredentials") };
			}
			this.setState({ errors, isLoading: false });
		}

	};

	handleInputChange = () => (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handlePasswordToggle = () => () => {
		this.setState({ passwordIsExposed: !this.state.passwordIsExposed });
	};

	render() {
		const {username, password, errors, passwordIsExposed, isLoading} = this.state;

		return <>
			<AppLoader isLoading={isLoading}>
			<Container className={"pos-container"}>
			</Container>
			<Modal
				className={s.modal}
				isOpen={true}
				size={"lg"}
				modalClassName={s.modalWrapper}
				contentClassName={s.modalContent}
			>
				<div className={s.logoWrapper}>
					<img className={s.logo} src={logo} />
				</div>
				<ModalBody className={s.modalBody}>

					<div className={s.formWrapper}>

						<form className={s.form} onSubmit={this.handleSubmit()}>
							<div className={s.inputFields}>
								<div className={s.title}>
									POS
								</div>
								<div className={s.fieldWrapper}>
									<div className={s.inputWrapper}>
										<HoveringInputField
											prefix={<img src={userIcon}/>}
											type={"email"}
											name={"username"}
											value={username}
											onChange={this.handleInputChange()}
											placeholder={t('LOGIN.username')}
											error={errors.username}
										/>
									</div>

								</div>
								<div className={s.fieldWrapper}>
									<div className={s.inputWrapper}>
										<HoveringInputField
											prefix={<img src={lockIcon}/>}
											type={passwordIsExposed ? "text" : "password"}
											name={"password"}
											value={password}
											onChange={this.handleInputChange()}
											placeholder={t('LOGIN.password')}
											error={errors.password}
										/>
										<div onClick={this.handlePasswordToggle()}  className={s.decoratorAfter}>
											<span className={classnames(s.decoratorText, s.clickable)}>{passwordIsExposed ? t("LOGIN.hidePassword") : t("LOGIN.showPassword") }</span>
											<img src={passwordIsExposed ? eyeOpenIcon : eyeClosedIcon}  />
										</div>
									</div>
								</div>
							</div>
							<YellowButton type="submit" onClick={this.handleSubmit()} lg={"true"} className={s.loginButton}>
								{t('LOGIN.login')}
							</YellowButton>
						</form>
					</div>

				</ModalBody>
				<Footer borderless={true} />
			</Modal>
			</AppLoader>
		</>

	}
}

function mapState (state) {
	return {
		user: state.user.data,
	}
}

function mapActions(dispatch) {
	return bindActionCreators({getUser, setLanguage, getRevertPermission}, dispatch);
}



export default withRouter(connect(mapState, mapActions)(Login));
