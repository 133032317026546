import React from 'react'
import Card from "reactstrap/es/Card";
import {formatDecimals, renderTotal} from "../../util";
import { getCurrencyName, renderReportAmount, renderReportCryptoAmount } from "../../utils/archive";
import t, {isCurrencyEuro, getCurrency} from "../../translation/translation";

import css from './report.module.css'
import * as classnames from 'classnames'

class ReportData extends React.Component{

    getClassName = (className) => {
        if(this.props.iframe){
            return className
        }
        return classnames(css[className])
    };

    render(){

        const { context, reports, renderTotal } = this.props;

        if(!reports.data) return <div></div>;

        if(reports.data.length === 0) {
            return(
                <div className={this.getClassName('noTransactionWrapper')}>
                    <div className={this.getClassName('line')}></div>
                    <span className={this.getClassName('message')}>{t("REPORT.dailyReport.noTransactions")}</span>
                </div>
            )
        }

        return(
            <div className={this.getClassName('fullContainer')}>
                {reports.data.map((item, index) => {
                    return (
                        <Card className={this.getClassName('wrapper')} key={index}>
                            <div className={this.getClassName('top')}>
                                <span>{getCurrencyName(item, context)}</span>
                                <span>{renderReportCryptoAmount(item, context)}</span>
                            </div>
                            <div className={this.getClassName('bottom')}>
                                <span>{t("REPORT.dailyReport.currencyInLabel")} {getCurrency().pos_display_name}</span>
                                <span>{renderReportAmount(item, this)}</span>
                            </div>
                        </Card>
                    );
                })}
                <Card className={this.getClassName('totalWrapper')}>
                    <div className={this.getClassName('totalRow')}>
                        <span>{t("REPORT.dailyReport.totalLabel")} {getCurrency().pos_display_name}</span>
                        <span>{renderTotal()} </span>
                    </div>
                </Card>
            </div>
        )
    }
}

export default ReportData