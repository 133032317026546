
import checkIcon from "../assets/stateSuccess.svg";
import clockIcon from "../assets/stateTimeout.svg";
import mobileCheckIcon from "../assets/Success_mobile.svg";
import underpaidIcon from '../assets/underpaid.svg'


export const paymentStatusTypes = {
    2: {
        type: "CRYPTO_PAYMENT_STATUS_PAID",
        statusId: 2,
        cssClass: "green",
        icon: checkIcon,
        mobileIcon: mobileCheckIcon
    },
    4: {
        type: "CRYPTO_PAYMENT_STATUS_AUTO_CLOSED",
        statusId: 4,
        cssClass: "red",
        amountStatus: "rgb(244, 245, 250)",
        icon: clockIcon,
        mobileIcon: clockIcon
    },
    5: {
        type: "CRYPTO_PAYMENT_STATUS_FAILED",
        statusId: 5,
        cssClass: "red",
        amountStatus: "rgb(244, 245, 250)",
        icon: clockIcon,
        mobileIcon: clockIcon
    },
    9: {
        type: "CRYPTO_PAYMENT_STATUS_UNDERPAID",
        statusId: 9,
        cssClass: "red",
        amountStatus: "rgb(244, 245, 250)",
        icon: underpaidIcon,
        mobileIcon: underpaidIcon
    }
};
