import React from 'react';
import {Button} from "reactstrap";
import * as classnames from "classnames";
import css from "./ThemedButton.module.css";


// Set prop types
type ThemedButtonProps = {};

class DarkThemedButton extends React.Component<ThemedButtonProps> {

    render() {
        let {color, lg} = this.props;
        const darkBlue =  !color || color === "darkBlue";

        return (
            <Button {...this.props}
                    outline={this.props.outline}
                    className={classnames(css.button, {[css.largeButton]: lg}, {[css.darkBlue]:darkBlue }, this.props.className)}
                    onClick={this.props.onClick}>
                { this.props.children }
            </Button>
        );
    };

}

// Set default props
DarkThemedButton.defaultProps = {};

export default DarkThemedButton;
