import { SLOVENIAN } from "./sl";
import { CROATIAN } from "./hr";
import { ENGLISH } from "./en";
import { TURKISH } from "./tr";
import { SWITZERLAND } from "./ch";
import { SPANISH } from "./es";
import { JAPANESE } from "./ja";
import { RUSSIAN } from "./ru.js";
import { HUNGARIAN } from "./hu";
import { SLOVAK } from "./sk";
import { PORTUGUESE } from "./pt";
import { ITALIAN } from "./it";
import { UKRAINIAN } from "./uk";

export const supportedLanguages = {
  en: {
    name: "English",
    code: "en",
    tag: "english",
    translation: ENGLISH,
  },
  sl: {
    name: "Slovene",
    code: "sl",
    tag: "slovene",
    translation: SLOVENIAN,
  },
  hr: {
    name: "Croatian",
    code: "hr",
    tag: "croatia",
    translation: CROATIAN,
  },
  tr: {
    name: "Turkish",
    code: "tr",
    tag: "turkey",
    translation: TURKISH,
  },
  ch: {
    name: "English",
    code: "ch",
    tag: "switzerland",
    translation: SWITZERLAND,
  },
  es: {
    name: "Spanish",
    code: "es",
    tag: "spanish",
    translation: SPANISH,
  },
  ja: {
    name: "Japanese",
    code: "ja",
    tag: "japan",
    translation: JAPANESE,
  },
  ru: {
    name: "Russian",
    code: "ru",
    tag: "russia",
    translation: RUSSIAN,
  },
  hu: {
    name: "Hungarian",
    code: "hu",
    tag: "hungary",
    translation: HUNGARIAN,
  },
  sk: {
    name: "Slovak",
    code: "sk",
    tag: "slovak",
    translation: SLOVAK,
  },
  pt: {
    name: "Portugese",
    code: "pt",
    tag: "portugese",
    translation: PORTUGUESE,
  },
  it: {
    name: "Italian",
    code: "it",
    tag: "italian",
    translation: ITALIAN,
  },
  uk: {
    name: "Ukrainian",
    code: "uk",
    tag: "ukrainian",
    translation: UKRAINIAN,
  },
};
