import React, {Fragment} from 'react';
import { NavLink } from 'react-router-dom'
import css from './Sidebar.module.css';
import * as classnames from "classnames";
import elipayLogo from "../../assets/Elipay_logo.svg";
import 'hamburgers/dist/hamburgers.min.css';
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router";
import logoutIcon from "../../assets/logout-icon.svg";
import elipayLogoCollapsed from "../../assets/Elipay_logo_collapsed.svg";
import elipayCollapseIcon from "../../assets/Collapse_menu_2_icon.svg";
import goCryptoLogo from "../../assets/primaryLogo.svg";
import goCryptoLogoCollapsed from "../../assets/GoCryptoLogoCollapsed.svg";
import goCryptoCollapseIcon from "../../assets/collapseButton.svg";
import goCryptoExpandIcon from "../../assets/expandButton.svg";
import {replace} from "connected-react-router";
import t, {isGoCrypto} from "../../translation/translation";
import auth from "../../api/auth";
import {routes} from "../../utils/routes";
import Col from "reactstrap/es/Col";
import {clearUser} from "../../redux/user";
import {Container} from "reactstrap";
import {getSafeDeep} from "../../utils/state";

// Set prop types
type SidebarProps = {};

class Sidebar extends React.Component<SidebarProps> {
    constructor(props) {
        super(props);
        this.state = {
            sidebarIsOpen: true,
            winWidth: window.innerWidth
        };
    }
    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }
    componentWillUnmount(){
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({
            winWidth: window.innerWidth
        })
    };

    toggle = (key) => () => {
        const fullKey = `${key}IsOpen`;
        this.setState({ [fullKey]: !this.state[fullKey] });
    };

    handleLogout = () => async() => {
        await auth.logout();
        await this.props.clearUser();
        this.props.replace(routes.login.path);
    };

      renderLink(route, isMobile) {
        const activePath = this.props.location.pathname === route.path;
        return <div key={route.path} className={classnames(css['linkWrapper'], {[css['active']]: activePath},"w-100 d-flex align-items-center")}>
            <NavLink exact={true} to={route.path} activeClassName={css['linkActive']}
                     className={classnames(css.link, "sidebarLink")}>
                <img alt={"Route icon"} className={css.icon} src={activePath ? route.activeIcon : route.icon} />
                <span className={isMobile ? css['mobileLabel'] : ""}>{t(`SIDEBAR.${route.key}`)}</span>
            </NavLink>
        </div>

    }

    renderLinks(isMobile = false) {
        return <Fragment>
            {Object.keys(routes).map(key => {
                    if (routes[key].authenticated || routes[key].public) {
                        return this.renderLink(routes[key], isMobile);
                    }
                }
            )}
        </Fragment>
    }


    renderSidebar() {
        const {sidebarIsOpen} = this.state;
        const {user} = this.props;
        const goCrypto = isGoCrypto();
        const logo = goCrypto ? goCryptoLogo : elipayLogo;
        const logoCollapsed = goCrypto ? goCryptoLogoCollapsed : elipayLogoCollapsed;
        const collapseIcon = goCrypto ? goCryptoCollapseIcon : elipayCollapseIcon;
        const expandIcon = goCryptoExpandIcon;
        const collapsed = sidebarIsOpen ? "" : "collapsed";
        const active = sidebarIsOpen ? "hamburgerIsActive" : "";
        const show = this.state.winWidth < 980 ? css.closed : "";
        return <nav id="sidebar" className={classnames("position-relative p-0", collapsed, css.navigation, show)}>

            <div id="sidebarContent" className={css.sidebarContent}>
                <NavLink className={classnames(css['logoWrapper'], {[css.gocrypto]: goCrypto})} to="/">
                    <img alt={"Logo"} className={classnames(css.logo, "logo")} src={logo} />
                    <img alt={"Collapsed logo"} className={classnames(css.logo, css['logoCollapsed'], "logoCollapsed")} src={logoCollapsed} />
                </NavLink>

                <button onClick={this.toggle('sidebar')} className={classnames("hamburger hamburger--slider", active, css.toggle)} type="button">
                    <img alt={"Collapse icon"} src={sidebarIsOpen ? collapseIcon : expandIcon} />
                </button>
                <div className={classnames(css.info, {[css.hideText]: !sidebarIsOpen})}>
                    <div className={css.infoText}>
                        POS
                    </div>
                    <div className={css.infoText}>
                        {getSafeDeep(user, "source.0.full_name", "Store")}
                    </div>
                </div>


                <Col className="px-0" >
                    <Col className={css.linksContainer}>
                        <div>
                            {this.renderLinks()}
                        </div>
                    </Col>
                    <div className={classnames(css.callContainer, {[css.hideText]: !sidebarIsOpen})}>
                        <div className={css.infoText}>
                            {t('HEADER.callAgent')}
                        </div>
                        <div className={css.phoneNumber}>
                            {t('HEADER.agentNumber')}
                        </div>
                    </div>

                </Col>
                <div onClick={this.handleLogout()} className={classnames(css['linkWrapper'], css['logoutWrapper'], "w-100 d-flex align-items-center")}>
                    <img alt={"Logout icon"} className={css.icon} src={logoutIcon} />
                    <button  className={classnames(css.logout, css.link, "logout")}>
                        {t('SIDEBAR.logout')}
                    </button>
                </div>

            </div>
        </nav>
    }

    renderMobileNavigation() {
        if (!this.props.navigationVisible) return <></>;
        const show = this.state.winWidth > 980 ? css.closed : "";
        return <nav className={classnames(css.wrapperMobile, show)}>
            <Container className={css.container}>
                {this.renderLinks(true)}
                <div onClick={this.handleLogout()} className={classnames(css['linkWrapper'], css['logoutWrapper'], "w-100 d-flex align-items-center")}>
                    <img alt={"Logout icon"} className={css.icon} src={logoutIcon} />
                    <button  className={classnames(css.logout, css.link, "logout")}>
                        {t('SIDEBAR.logout')}
                    </button>
                </div>
            </Container>

        </nav>
    }

    render() {
        return(
            <>
                {this.renderSidebar()}
                {this.renderMobileNavigation()}
            </>
        );
    };

}

// Set default props
Sidebar.defaultProps = {
    history: {}
};

function mapState(state){
    return {
        user: state.user.data,
        navigationVisible: getSafeDeep(state.navigationVisible, "navigationVisible", true)
    }
}


function mapActions(dispatch) {
    return bindActionCreators({
        replace, clearUser
    }, dispatch);
}

export default withRouter(connect(mapState, mapActions)(Sidebar));
