import React from 'react';
import {Button} from "reactstrap";
import * as classnames from "classnames";
import css from "./ThemedButton.module.css";
import {Link} from "react-router-dom";


// Set prop types
type ThemedButtonProps = {};

class ThemedButton extends React.Component<ThemedButtonProps> {

    render() {
        let {color, link, linkTo} = this.props;
        const lightBlue =  !color || color == "lightBlue";
        if (link) return <Link className={classnames(this.props.className, css.button, {[css.lightBlue]:lightBlue },
            "btn-outline btn ")} to={linkTo}>
            { this.props.children }
        </Link>;
        return (
            <Button {...this.props}
                    outline={this.props.outline}
                    className={classnames(this.props.className, css.button, {[css.lightBlue]:lightBlue })}
                    onClick={this.props.onClick}>
                { this.props.children }
            </Button>
        );
    };

}

// Set default props
ThemedButton.defaultProps = {};

export default ThemedButton;
