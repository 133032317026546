/**/
import React, { Component } from 'react'
import s from './ProgressBar.module.css'

class ProgressBar extends Component {

	state = {
		width: 0
	};

	componentDidUpdate(prevProps) {
		if (prevProps.step != this.props.step) {
			this.setState({
				width: ((this.props.step + 1) * 33 ) + 1
			})
		}
	}

	componentDidMount() {
		this.setState({ width: 33 });
	}

	render() {
		return <div className={s.wrapper}>
			<div className={s.padding}>
			</div>
			<div className={s.barWrapper}>
				<div style={{ width: `${this.state.width}%`}} className={s.bar}>
				</div>
			</div>

		</div>

	}

}

export default ProgressBar
