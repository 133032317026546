/**/
import React, { Component } from 'react'
import s from './ReferenceNumberInput.module.css'
import * as classnames from "classnames";
import t from "../../translation/translation";
import {Input} from "reactstrap";
import pencilIcon from "../../assets/pencilIcon.svg";
import HoveringInputField from "../HoveringInputField/HoveringInputField";

class ReferenceNumberInput extends Component {

	componentDidMount() {
	}

	render() {
		const {referenceNumber, handleReferenceNumberChange, disabled} = this.props;
		return <div className={classnames(s.wrapper, disabled && s.disabled)}>


			<HoveringInputField
				prefix={<img src={pencilIcon} />}
				maxLength={30}
				style={{fontSize: 13}}
				name="referenceNumber"
				placeholder={t('PAYMENT.referenceNumberLabel')}
				value={referenceNumber}
				disabled={disabled}
				onChange={handleReferenceNumberChange}
			/>

		</div>
	};

}

export default ReferenceNumberInput;
