/**/
import React, { Component } from 'react'
import s from './POSTooltip.module.css'
import TransparentButton from "../ThemeButton/TransparentButton";
import whiteCloseIcon from "../../assets/whiteCloseIcon.svg";
import LightThemeButton from "../ThemeButton/LightThemeButton";
import cx from "classnames";
import t, {getLanguage} from "../../translation/translation";
import {getSafeDeep} from "../../utils/state";
import {getDeviceKey} from "../../utils/devices";

const arrowTypes = {
	left: s.rightArrow,
	bottom: s.topArrow
};

class POSTooltip extends Component {

	state = {
		width: window.innerWidth
	};

	componentDidMount() {
		this.updateDimensions();
		window.addEventListener("resize", this.updateDimensions);
	}
	componentWillUnmount(){
		window.removeEventListener("resize", this.updateDimensions);
	}

	updateDimensions = () => {
		this.setState({
			width: window.innerWidth
		})
	};

	getArrowUIData = () => {
		const {tooltip, tooltipStep, hideOn} = this.props;
		const {width} = this.state;
		const stepInfo = tooltip.steps[tooltip.step];
		let style = {...stepInfo.mobileStyle};
		let arrowStyle = stepInfo.mobileArrow;
		let arrowType = arrowTypes[stepInfo.mobilePosition];
		if (width >= 980) {
			style = {...stepInfo.style};
			arrowStyle = stepInfo.arrow;
			arrowType = arrowTypes[stepInfo.position];
		}
		return {
			style,
			arrowStyle,
			arrowType
		}
	};

	isUIHidden = () => {
		const {tooltip, tooltipStep, hideOn} = this.props;
		const {width} = this.state;
		if (tooltipStep !== tooltip.step) return true;
		if (hideOn && width >= hideOn) return true;
		if (!tooltip.isOpen) return true;
		return false;
	};

	getDeviceTranslation = () => {
		const {width} = this.state;
		const {tooltip, tooltipStep, hideOn} = this.props;
		const stepInfo = tooltip.steps[tooltip.step];
		const key = `${stepInfo.key}.${getDeviceKey(width)}.${tooltipStep}`;
		return getSafeDeep(getLanguage(),key, "");
	};

	render() {
		if (this.isUIHidden()) return <></>;
		const {tooltip, tooltipStep, hideOn} = this.props;
		const stepInfo = tooltip.steps[tooltip.step];
		let {arrowType, style, arrowStyle} = this.getArrowUIData();
		const text = this.getDeviceTranslation();
		const stepString = getSafeDeep(getLanguage(), "PAYMENT.stepLabel", "");
		const toString = getSafeDeep(getLanguage(), "PAYMENT.fromLabel", "");

		return <div className={s.absoluteWrapper} style={ style }>
			<div className={s.wrapper} >

				<div className={s.header}>
					<div className={s.title}> {stepString} {tooltip.step + 1} {toString} {tooltip.steps.length}</div>
					<TransparentButton onClick={tooltip.closeTooltip()} className={s.closeIcon}><img src={whiteCloseIcon} /></TransparentButton>
				</div>
				<div className={s.text}>
					{text}
				</div>
				{tooltip.step !== tooltip.steps.length - 1 && <LightThemeButton onClick={tooltip.nextStep()} className={s.nextButton}>
                    {t('TOOLTIPS.next')}
				</LightThemeButton> }
				<div className={cx(s.arrow, arrowType)} style={arrowStyle}>

				</div>
			</div>
		</div>

	}

}

export default POSTooltip
