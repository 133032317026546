/**/
import React, { Component } from 'react'
import s from './PaymentStatus.module.css'
import YellowButton from "../ThemeButton/YellowButton";
import t from "../../translation/translation";
import cx from "classnames";
import AmountWidget from "../AmountWidget/AmountWidget";

class PaymentStatus extends Component {

	render() {
		if (this.props.step !== 2) return "";
		const { handlePaymentReset, step, amount, status: { cssClass, icon, type: status, mobileIcon }, euroAmount } = this.props;

		const statusMessage = t(`PAYMENT.paymentStatusLabel.${status}`).split('\n')

		return (
			<div className={cx(s.wrapper, cssClass, { "card-shadow": step === 2 })}>
				<div className={s.container}>
					<div className={s.status}>{statusMessage[0]}</div>
					<hr className={cx(s.divider, { [s.underpaid]: status == 'CRYPTO_PAYMENT_STATUS_UNDERPAID' })} />
					{status == 'CRYPTO_PAYMENT_STATUS_UNDERPAID' && <p className={s.statusDescription}>{statusMessage[1]}</p>}
					<div className={s.iconWrapper}>
						<img src={icon} />
					</div>
					<AmountWidget amount={amount} status={this.props.status} euroAmount={euroAmount} />
				</div>



				<div className={s.buttonWrapper}>
					<YellowButton onClick={handlePaymentReset} className={s.button}>
						{t('PAYMENT.resetPaymentButton')}
					</YellowButton>
				</div>

			</div>
		);
	};

}

export default PaymentStatus
