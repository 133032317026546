/**/
import React, { Component } from 'react'
import s from './ArchivePagination.module.css'
import {bindActionCreators} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import ReactPaginate from "react-paginate";
import {routes} from "../../utils/routes";
import iconRight from "../../assets/iconRightInactive.svg";
import iconLeft from "../../assets/iconLeftInactive.svg";
import {getSafeDeep} from "../../utils/state";
import {RESULTS_PER_PAGE} from "../../views/Archive/Archive";
import * as qs from "qs";
import {NavLink} from "react-router-dom";

class ArchivePagination extends Component {

	handlePageChange = () => (page) => {
		this.props.history.push(`${routes.transactions.path}?page=${page.selected}`);
	};


	render() {
		const {data, query} = this.props;
		if(data.count === 0) return "";
		const page = parseInt(getSafeDeep(this.props.query, "page", 0));
		const prevLink = page === 0 ? false : `${routes.transactions.path}?${qs.stringify({...query, page:page-1})}`;
		const nextLink = Math.max(((page + 1) * RESULTS_PER_PAGE), data.count) === (page + 1) * RESULTS_PER_PAGE ? false : `${routes.transactions.path}?${qs.stringify({...query, page:page+1})}`;
		return <div className={s.container}>
			<div>
				{page * RESULTS_PER_PAGE + 1} - {Math.min((page + 1) * RESULTS_PER_PAGE, data.count)} / {data.count}
			</div>
			<div className={s.arrows}>
				{ prevLink ?
					<NavLink to={prevLink} className={s.leftArrow}>
						<img src={iconLeft} />
					</NavLink>
					:
					<div className={s.leftArrow}>
						<img src={iconLeft} />
					</div>
				}

				{ nextLink ?
					<NavLink to={nextLink} className={s.rightArrow}>
						<img src={iconRight} />
					</NavLink>
					:
					<div className={s.rightArrow}>
						<img src={iconRight} />
					</div>
				}

			</div>
		</div>
	}

}

function mapState(state) {
	return {
		query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true })
	}
}

function mapActions(dispatch) {
	return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapState,mapActions)(ArchivePagination));
