import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Button, Container} from "reactstrap";
import css from "./report.module.css"
import {bindActionCreators} from "redux";
import {getCurrencies} from "../../redux/currency";
import report from "../../api/report";
import {renderTotal} from "../../util";
import {getSafeDeep} from "../../utils/state";
import printIcon from "../../assets/PrintWhite.svg";
import t from "../../translation/translation";
import DarkThemedButton from "../../components/ThemeButton/DarkThemeButton";
import * as classnames from "classnames";
import Loader from "../../components/Loader/Loader";
import ReportData from "./ReportData";
import { print } from './util'

class Report extends React.Component {

    constructor(props) {
        super(props);
        this.props.getCurrencies();
        this.state = {
            reports: [],
            isLoading: true,
            isDisabled: false
        }
    }

    async componentDidMount() {
        const data = await report.getReports();
        this.setState({
            reports: data,
            isLoading: false,
            isDisabled: getSafeDeep(data, "data.length", 0) === 0 ? true : false
        })
    }

    renderReportData = ({iframe}) => {
        const context = {currencies: this.props.currencies};
        return(
            <ReportData iframe={iframe}
                        context={context}
                        renderTotal={() => renderTotal(this.state.reports.data)}
                        reports={this.state.reports}/>
        )
    };

    render() {
        return (
            <Container className="pos-container">
                <div className={css.mainWrapper}>
                    <div className={css.topPart} >
                        <div className={css.title}>
                            {t("REPORT.dailyReport.title")}
                        </div>
                        {this.state.isDisabled ?
                            <Button className={classnames(css.printButton, css.disabledPrint)} disabled>
                                <img className={css.printIcon} src={printIcon}/>
                                <div className={css.printLabel}>{t("REPORT.dailyReport.print")}</div>
                            </Button>
                            :
                            <DarkThemedButton onClick={() => print(this.renderReportData)} className={css.printButton}>
                                <img className={css.printIcon} src={printIcon}/>
                                <div className={css.printLabel}>{t("REPORT.dailyReport.print")}</div>
                            </DarkThemedButton>}
                    </div>
                    <div className={this.state.isLoading ? css.loader : ""}>
                        <Loader isLoading={this.state.isLoading} propagate={false}>
                            {this.state.reports.data ?
                                <>
                                    <div className={classnames(css.labelContainer)}>
                                        <span className={classnames(css.label)}>{t("REPORT.dailyReport.currencyLabel")}</span>
                                        <span className={classnames(css.label)}>{t("REPORT.dailyReport.amountLabel")}</span>
                                    </div>
                                    <this.renderReportData />
                                </>
                                :null}
                        </Loader>
                    </div>
                </div>
            </Container>
        );

    };

    redirectExport() {
        window.open(report.getDailyReportUrl(), '_blank');
    }
}

function mapState(state) {
    return {
        status: state.status.data,
        currencies: state.currencies.data,
        language: getSafeDeep(state, "language.language.translation")
    };
}

function mapActions(dispatch) {
    return bindActionCreators({getCurrencies}, dispatch);
}


export default withRouter(connect(mapState, mapActions)(Report));
