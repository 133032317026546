
import {apiClientV3, apiClient} from './util';
import {AxiosPromise} from 'axios';


export default {
    getCurrencies: (): AxiosPromise => apiClientV3.get("/currencies"),
    getConversion: (id): AxiosPromise => apiClient.get(`/ticker/last?currency=${id}&value_only=true`),
    getEuroHrkConversion: (): AxiosPromise => apiClient.get("/ticker/last?currency=11"),
    getEuroTryConversion: (): AxiosPromise => apiClient.get("/ticker/last?currency=12"),
};
