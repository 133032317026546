/**/
import React, { Component } from 'react'
import s from './Archive.module.css'
import {Container} from "reactstrap";
import t from "../../translation/translation";
import DarkThemedButton from "../../components/ThemeButton/DarkThemeButton";
import LightThemeButton from "../../components/ThemeButton/LightThemeButton";
import ReactSVG from "react-svg";
import lightBulbIcon from "../../assets/lightBulb.svg";
import refreshIcon from "../../assets/refreshIcon.svg";
import TransparentButton from "../../components/ThemeButton/TransparentButton";
import exportIcon from "../../assets/exportIcon.svg";
import ArchiveToolbar from "../../components/ArchiveToolbar/ArchiveToolbar";
import ArchiveTable from "../../components/ArchiveTable/ArchiveTable";
import transactions from "../../api/transaction";
import Loader from "../../components/Loader/Loader";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as qs from "qs";
import {getSafeDeep} from "../../utils/state";
import ArchivePagination from "../../components/ArchivePagination/ArchivePagination";
import DateFilter from "../../components/DateFilter/DateFilter";
import {TooltipConsumer, TooltipProvider} from "../../context/TooltipContext";
import ProgressStep from "../../components/ProgressStep/ProgressStep";
import {PaymentConsumer} from "../Payment/PaymentContext";

export const RESULTS_PER_PAGE = 30;

const archiveTooltipSteps = [
	{
		key: "TOOLTIPS.ARCHIVE",
		position: "bottom",
		mobilePosition: "bottom",
		style: {
			top: 65,
		},
		mobileStyle: {
			top: 65
		},
		arrow: {
			left: 40
		},
		mobileArrow: {
			left: 40
		}
	},
	{
		key: "TOOLTIPS.ARCHIVE",
		position: "bottom",
		mobilePosition: "bottom",
		style: {
			top: 80,
			right: 10
		},
		mobileStyle: {
			top: 65,
			right: 10,
		},
		arrow: {
			right: 53,
			left: `auto`
		},
		mobileArrow : {
			right: 15,
			left: `auto`
		}
	}
];

class Archive extends Component {

	state = {
		data: [],
		isLoading: true,
		initialLoad: true,
		dateFilterOpen: false
	};

	loadData = () => async () => {
		this.setState({ isLoading: true }, async () => {
		    const page = getSafeDeep(this.props.query, "page", 0);
		    const begin = getSafeDeep(this.props.query, "begin", undefined);
		    const end = getSafeDeep(this.props.query, "end", undefined);
		    const successful = getSafeDeep(this.props.query, "successful", undefined);

			const transactionResponse = await transactions.getTransactions(page, begin, end, successful);
			this.setState({ data: transactionResponse.data, isLoading: false, initialLoad: false });
		});
	};

	refreshTransactions = () => async () => {
		await this.loadData()();
	};

	toggleDateFilter = () => () => {
		this.setState({ dateFilterOpen: !this.state.dateFilterOpen });
	};

	async componentDidMount() {
		await this.loadData()();
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.props.query.page !== prevProps.query.page || this.props.query.successful !== prevProps.query.successful) {
			this.loadData()();
		}
	}

	render() {
		const {data, isLoading, initialLoad, dateFilterOpen} = this.state;
		return <TooltipProvider steps={archiveTooltipSteps}>
			<Container className="pos-container">
			<div className={s.titleRow}>
				<div className={s.title}>
					{t('ARCHIVE.overviewTitle')}
				</div>
				<div className={s.headerWrapper}>
					<TooltipConsumer>
						{ tooltip => <LightThemeButton active={tooltip.isOpen} onClick={tooltip.openTooltip()} className={s.helpButton}>
							<ReactSVG src={lightBulbIcon} className={s.lightBulb} />
							<div className={s.helpLabel}>{t('HEADER.help')}</div>
						</LightThemeButton>}
					</TooltipConsumer>
					<TransparentButton disabled={isLoading} onClick={this.refreshTransactions()}>
						<img src={refreshIcon} />
					</TransparentButton>
				</div>
			</div>
				<TooltipConsumer>
					{ (tooltip) => <ArchiveToolbar {...{tooltip: tooltip}} loadData={this.loadData()} toggleDateFilter={this.toggleDateFilter()} /> }
				</TooltipConsumer>
			{!dateFilterOpen &&
			<Loader propagate={!initialLoad} className={"mt-5"} isLoading={isLoading}>

					<ArchiveTable loadData={this.loadData()} data={data.results} />
					<ArchivePagination data={data} />

			</Loader>
			}



		</Container>
		</TooltipProvider>

	}

}


function mapState(state) {
	return {
		query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true })
	}
}

function mapActions(dispatch){
	return bindActionCreators({}, dispatch);
}

export default connect(mapState, mapActions)(Archive);

