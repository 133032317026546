import React from "react";
import {Container} from "reactstrap";
import t from "../../translation/translation";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            // Render fallback UI with translated strings
            return <Container className={"text-center"}>
                <strong>{t("ERROR.unknownCause")}.</strong>
                <br/>
                <strong>{t("ERROR.reload")}.</strong>
                <br/>
                <strong>{t("ERROR.report")}.</strong>
            </Container>;
        }
        return this.props.children;
    }
}


export default ErrorBoundary;
