import ReactDom from "react-dom";
import React from "react";
import axios from 'axios'

export async function print(Component){

    //check if frame already exists
    let printFrame = window.frames["printFrame"];

    // if frame does not exist create new one
    if(!printFrame){
        let frame = document.createElement('iframe');
        frame.setAttribute("id", "printFrame");
        document.body.appendChild(frame);
        printFrame = window.frames["printFrame"];
    }
    //create styles
    const styles = [];
    const { data: bootstrap } = await axios.get('https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css')
    styles.push(`<style>${bootstrap}</style>`);
    styles.push(`<style>${reportStyles}</style>`);

    //append styles
    printFrame.contentDocument.head.innerHTML = styles.join('\n');

    //render component in iframe and print it
    ReactDom.render(<Component iframe />, printFrame.contentDocument.body);
    printFrame.contentWindow.print();
}

const reportStyles = `
* {
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    color-adjust: exact !important;  /*Firefox*/
}

@page { 
    margin: 0cm !important;
}

body, html{
    margin: 0 !important;
    padding: 0 !important;
}

.fullContainer
{
    width: 100%;
}
.wrapper, .totalWrapper
{
    width: 100%;
    border-radius: 1px;
    color: #00141b;
    font-size: 13px;
    letter-spacing: 0px;
    padding: 15px;
    display:flex;
    justify-content: center;
}

.wrapper
{
    font-weight: normal;
    height: 67px;
    background: white;
    border: 1px solid white;
    margin-top: 4px;
}
.totalWrapper
{
    background: #e5e8e8;
    border: 1px solid #e5e8e8;
    font-weight: bold;
    height: 46px;
    margin-top: 6px;
}
.printNone
{
    display: none !important;
}
.totalRow
{
    width: 100%;
    font-weight: bold;
}
.bottom, .totalRow, .top
{
    display: flex;
    justify-content: space-between;
}
.bottom
{
    font-weight: bold;
}
.top
{
    text-align: right;
}
.message
{
    color: rgba(127, 140, 141, 0.7);
    font-size: 13px;
    font-weight: normal;
    height: 16px;
    letter-spacing: 0px;
}
.line
{
    background: rgb(255, 255, 255);
    border-radius: 1px;
    height: 2px;
    width: 745px;
}
.disabledPrint
{
    display:none;
}
.total
{
    margin-bottom: 0;
}

.wrapper
{
    width: 50%;
    margin: 0 !important;
}

.container
{
    width: 210mm !important;
    display: flex !important;
    justify-content: center !important;
    padding: 0;
}
`;