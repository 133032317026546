import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import currencies from "./currency";
import conversionRate from "./conversionRate";
import language from "./language";
import status from "./status";
import user from "./user";
import navigationVisible from "./navigation";
import supportedLanguages from "./supportedLanguage";
import revertPermission from "./revertPermission";

export default (history) => combineReducers({
    router: connectRouter(history),
    currencies,
    conversionRate,
    language,
    supportedLanguages,
    status,
    user,
    navigationVisible,
    revertPermission
});
