
import {apiClient} from './util';
import {AxiosPromise} from 'axios';


export default {
    postPayment: (amount, currency, reference_number): AxiosPromise => {
        let requestBody = {
            amount,
            currency: 0,
            reference_number
        };

        return apiClient.post("/payment/crypto/pos", requestBody);
    },

    cancelPayment: (id): AxiosPromise => {
        const requestBody = {
            id
        };
        return apiClient.put("/payment/cancel", requestBody);
    },

    alternatePayment: (amount, currency, reference_number, alternatePayment): AxiosPromise => {
        let requestBody = {
            amount,
            currency: 0,
            reference_number,
            equivalent_value: alternatePayment
        };

        return apiClient.post("/payment/crypto/pos", requestBody);
    },

    getPaymentStatus: (paymentId) => {
        return apiClient.post("/payment", {
            payment_id: paymentId,
            payment_option_enable: false,
            expand: ["status"]
        });
    },

    revertPayment: (paymentId) => {
        return apiClient.put("/payment/revert", {id: paymentId});
    }
};
