import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import promise from "redux-promise-middleware";
import reducers from "./redux";

export const currentHistory = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = applyMiddleware(
  routerMiddleware(currentHistory),
  promise,
  thunk
);

export default function configureStore(preloadedState) {
  return createStore(
    reducers(currentHistory),
    preloadedState,
    composeEnhancers(middleware)
  );
}
