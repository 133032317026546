import {fulfilled, fulfilledState, initState, pending, pendingState, rejected, rejectedState} from "./util";
import currency from "../api/currency";

export const GET_CONVERSION_RATE = "GET_CONVERSION_RATE";

export default function (state = initState, action) {
    switch (action.type) {
        case pending(GET_CONVERSION_RATE):
            return pendingState(state);
        case fulfilled(GET_CONVERSION_RATE):
            return fulfilledState(state, action.payload.data);
        case rejected(GET_CONVERSION_RATE):
            return rejectedState(state, action.payload);
        default:
            return state;
    }
}

export const getConversionRate = (id = 11) => ({
    type: GET_CONVERSION_RATE,
    payload: currency.getConversion(id)
});

