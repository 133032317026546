/**/
import React, { Component } from 'react'
import s from './ArchiveTable.module.css'
import {
	checkBooleanPermission,
	getCurrencyName,
	getRevertPermission,
	renderCombinedAmount, renderCryptoAmount,
	renderDateTime,
	renderFiatAmount, renderPrintButton,
	renderReferenceNumber, renderRevertButton,
	renderStatus
} from "../../utils/archive";
import t, {getCurrency} from "../../translation/translation";
import cx from "classnames";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import printIcon from "../../assets/printIcon.svg";
import { print } from './util'
import TransparentButton from "../ThemeButton/TransparentButton";
import payment from "../../api/payment";

const tableHeaders = [
	{
		labelKey: "ARCHIVE.tableHeaders.lastUpdated",
		key: renderDateTime,
		className: "",
		permission: false
	},
	{
		labelKey: "ARCHIVE.tableHeaders.combinedAmount",
		key: renderCombinedAmount,
		className: "",
		permission: false
	},
	{
		labelKey: "ARCHIVE.tableHeaders.referenceNumber",
		key: renderReferenceNumber,
		className: "mobile-hidden",
		permission: false
	},
	{
		labelKey: "ARCHIVE.tableHeaders.status",
		key: renderStatus,
		className: s.status,
		permission: false
	},
	{
		labelKey: "ARCHIVE.tableHeaders.revert",
		key: renderRevertButton,
		className: "mobile-hidden",
		permission: checkBooleanPermission
	},
	{
		labelKey: "ARCHIVE.printLabel",
		key: renderPrintButton,
		className: "mobile-hidden",
		id: "print",
		permission: false
	}
];


class ArchiveTable extends Component {

	state = {
		activeRow: undefined
	};

	PrintComponent = ({ activeRow }) => {
		return (
			<div className={cx("d-print-block", s["print-container"])}>
				{this.renderPrint(activeRow)}
			</div>
		)
	};

	renderTableHeaders() {
		const {user} = this.props;
		return tableHeaders.filter(headerRaw => headerRaw.permission === false || (headerRaw.permission !== false && headerRaw.permission(this.props.revertPermission))).map(header => {
			return <th key={header.labelKey} className={cx(s.headerCell, header.className)}>
				{t(header.labelKey)}
			</th>
		})
	}

	setActiveRow = (row) => () => {
		this.setState({activeRow: row}, () => {});
	};

	handleRevert = (id) => async () => {
		await payment.revertPayment(id);
		this.props.loadData();
	};

	renderTableRow(row) {
		const context = { currencies: this.props.currencies, revert: this.handleRevert(row.id) };
		const {user} = this.props;
		return <tr key={row.id} className={s.row}>{tableHeaders.filter(headerRaw => headerRaw.permission === false || (headerRaw.permission !== false && headerRaw.permission(this.props.revertPermission))).map(header => {

			if(header.id === "print") {
				return <td onMouseEnter={this.setActiveRow(row)} key={header.labelKey} className={cx(s.cell, header.className)}>
					<TransparentButton onClick={() => print(() => <this.PrintComponent activeRow={this.state.activeRow} />)}>
						<img src={printIcon} />
					</TransparentButton>
				</td>
			}

			return <td key={header.labelKey} className={cx(s.cell, header.className)}>
				{header.key(row, context)}
			</td>
		})}

		</tr>
	}

	renderEmptyRows() {
		return <div className={s.emptyMessage}>
			{t("ARCHIVE.emptyList")}
		</div>
	}

	renderTableRows() {
		const {data} = this.props;
		return data.map(row => this.renderTableRow(row));

	}

	renderPrint(row) {
		const context = { currencies: this.props.currencies };
		let isCryptoVisible = '';
		if(row) isCryptoVisible = row.crypto_currency ? '' : 'd-none';
		return <div className={s.printPreview}>
			<div>GoCrypto</div>
			<div>{t('ARCHIVE.print.transactionInfoLabel')}</div>
			<div>-------------------------</div>
			<div>{t('ARCHIVE.print.dateTimeLabel')}</div>
			<div>{renderDateTime(row, {}, true)}</div>
			<div className={isCryptoVisible}>{t( "ARCHIVE.print.amountLabel")} {getCurrencyName(row, context)}</div>
			<div className={isCryptoVisible}>{renderCryptoAmount(row, context)}</div>
			<div>{t("ARCHIVE.print.amountLabel")} {getCurrency().name}</div>
			<div>{renderFiatAmount(row, context)}</div>
		</div>
	}

	render() {
		const {isLoading, data, revertPermission} = this.props;
		const {activeRow} = this.state;
		return <div className={cx({[s.loader]: isLoading}, "d-print-none")}>
				<table className={s.table}>
					<thead className={s.header}>
						<tr  className={s.headerRow}>
							{this.renderTableHeaders()}
						</tr>
					</thead>
					<tbody>
						{this.renderTableRows()}
					</tbody>
				</table>
				{data.length === 0 && this.renderEmptyRows()}
				{<this.PrintComponent activeRow={activeRow} />}
			</div>

	}

}
function mapState(state) {
	return {
		user: state.user.data,
		currencies: state.currencies.data,
		revertPermission: state.revertPermission.data
	}
}

function mapActions(dispatch) {
	return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapState,mapActions)(ArchiveTable));
