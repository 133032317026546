import React from 'react';
import ReactDOM from 'react-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.scss';
import * as serviceWorker from './serviceWorker';
import AppRouter from "./AppRouter";
import {ConnectedRouter} from "connected-react-router";
import {Provider} from "react-redux";
import store, {currentHistory} from "./store";

export const initStore = store();

ReactDOM.render(<Provider store={initStore}>
                <ConnectedRouter history={currentHistory}>
                    <AppRouter  />
                </ConnectedRouter>

                </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
