/**/
import React, { Component } from 'react'
import s from './DateFilter.module.css';
import {DateRangePicker} from "react-dates";
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import moment from "moment";
import arrowIconLeft from "../../assets/archiveIconLeft.svg";
import {isOutsideRange} from "../../utils/archive";
import Media from "react-media";

class DateFilter extends Component {

	state = {
		startDate: moment().subtract(1),
		endDate: moment(),
		focusedInput: null
	};

	renderMobilePicker() {
		return <DateRangePicker
			startDate={this.state.startDate} // momentPropTypes.momentObj or null,
			displayFormat={"DD. MMM YYYY"}
			orientation={"vertical"}
			verticalHeight={500}
			keepOpenOnDateSelect={true}
			startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
			endDate={this.state.endDate} // momentPropTypes.momentObj or null,
			endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
			onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
			focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
			onClose={() => {}}
			customArrowIcon={<img src={arrowIconLeft} />}
			hideKeyboardShortcutsPanel={true}
			onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
		/>
	}

	render() {
		return <div>
			<div className={"mobile-hidden"}>
				asdasdas
				<DateRangePicker
					startDate={this.state.startDate} // momentPropTypes.momentObj or null,
					displayFormat={"DD. MMM YYYY"}
					keepOpenOnDateSelect={true}
					startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
					endDate={this.state.endDate} // momentPropTypes.momentObj or null,
					endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
					onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
					focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
					onClose={() => {}}
					customArrowIcon={<img src={arrowIconLeft} />}
					hideKeyboardShortcutsPanel={true}
					onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
				/>
			</div>

			{this.renderMobilePicker()}
		</div>

	}

}

export default DateFilter
