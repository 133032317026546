import { initState} from "./util";

//const
export const TOGGLE_NAVIGATION = "TOGGLE_NAVIGATION";

export default function (state = initState, action) {
    switch (action.type) {
        case TOGGLE_NAVIGATION:
            return {...state, navigationVisible: action.payload};
        default:
            return state;
    }
}

//action
export const setNavigationVisible = (navigationVisible) => ({
    type: TOGGLE_NAVIGATION,
    payload: navigationVisible
});
