import React from 'react';

export const TooltipContext = React.createContext('payment');


export class TooltipProvider extends React.Component {
    state = {
        step: 0,
        isOpen: false,
        steps: this.props.steps
    };

    openTooltip = () => () => {
        this.setState({isOpen: true});
    };

    closeTooltip = () => () => {
        this.setState({isOpen: false, step: 0});
    };

    nextStep = () => () => {
        if (this.state.step < this.props.steps.length - 1) {
            this.setState({step: this.state.step + 1});
        }
    };

    prevStep = () => () => {
        if (this.state.step === 0) {
            this.setState({isOpen: false, step: 0});
        } else {
            this.setState({step: this.state.step - 1});
        }
    };

    render() {
        const values = {
            ...this.state,
            nextStep: this.nextStep,
            prevStep: this.prevStep,
            openTooltip: this.openTooltip,
            closeTooltip: this.closeTooltip
        };
        return (
            <TooltipContext.Provider value={values}>
                {this.props.children}
            </TooltipContext.Provider>
        );
    }


}


export const TooltipConsumer = TooltipContext.Consumer;
