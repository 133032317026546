// @flow
import axios, {AxiosInstance} from "axios";
import {API_URL, ELIPAY_LOCAL_STORAGE} from "../config";
import {getSafeDeep} from "../utils/state";
import {initStore} from "../index";
import {currentHistory} from "../store";
import {routes} from "../utils/routes";

export let apiClient: AxiosInstance = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken'
});

export let apiClientV3: AxiosInstance = axios.create({
    baseURL: API_URL.replace("v2","v3"),
    withCredentials: true,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken'
});



apiClient.interceptors.request.use(
    config => {
        const language = getSafeDeep(JSON.parse(localStorage.getItem('Elipay-POS')), 'language.code', 'en');
        config.headers['Accept-Language'] = language;
        return config;
    },
    error => Promise.reject(error)
);

function maintenanceMiddlewareResponse(response) {
    if (localStorage.getItem(`${ELIPAY_LOCAL_STORAGE}/maintenanceMode`) === 'true') {
        localStorage.setItem(`${ELIPAY_LOCAL_STORAGE}/maintenanceMode`, false);
        window.location.reload();
    }
    return response;
}

function maintenanceMiddlewareError(error) {
    let mockError = JSON.parse(JSON.stringify(error));
    let isMaintenanceMode = localStorage.getItem('${ELIPAY_LOCAL_STORAGE}/maintenanceMode');
    isMaintenanceMode = isMaintenanceMode === 'false' || !isMaintenanceMode ? false : true;
    if (getSafeDeep(error,'response.status') === 503) {
        mockError = {...mockError, response: { status: 401 }};
        if (!isMaintenanceMode) {
            localStorage.setItem(`${ELIPAY_LOCAL_STORAGE}/maintenanceMode`, true);
            currentHistory.push(routes.maintenance.path);
        }
    } else {
        if (isMaintenanceMode) {
            localStorage.setItem(`${ELIPAY_LOCAL_STORAGE}/maintenanceMode`, false);
            window.location.reload();
        }

    }
    return mockError;
}

apiClient.interceptors.response.use(
    maintenanceMiddlewareResponse,
    maintenanceMiddlewareError
);

apiClientV3.interceptors.response.use(
    maintenanceMiddlewareResponse,
    maintenanceMiddlewareError
);



apiClientV3.interceptors.request.use(
    config => {
        const language = getSafeDeep(JSON.parse(localStorage.getItem('Elipay-POS')), 'language.code', 'en');
        config.headers['Accept-Language'] = language;
        return config;
    },
    error => Promise.reject(error)
);

