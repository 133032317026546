/**/
import React, { Component } from 'react'
import Media from "react-media";
import s from "./ProgressStep.module.css";
import * as classnames from "classnames";
import t from "../../translation/translation";

const steps = [
	{
		stepNumber: 0,
		key: "PAYMENT.createPayment",
	},
	{
		stepNumber: 1,
		key: "PAYMENT.paymentReady",
	},
	{
		stepNumber: 2,
		key: "PAYMENT.paymentFinished",
	},
];

class ProgressStep extends Component {

	renderProgressBar() {
		const {step} = this.props;
		return <div className={s.wrapper}>
			{steps.map(progressStep => {
				const activeClassName = step >= progressStep.stepNumber ? s.active: "";
				return <div key={progressStep.stepNumber} className={classnames(s.stepWrapper)}>
					<div className={classnames(step === progressStep.stepNumber ? activeClassName : "", s.label)}>
						{progressStep.stepNumber + 1}. {t(progressStep.key)}
					</div>

				</div>
			})}
		</div>
	}

	renderProgressBarMobile() {
		const {step} = this.props;
		return <div className={s.mobileWrapper}>
			<div>
				{step + 1}. {t(steps[step].key)}
			</div>
			<div>
				{step + 1} {t('PAYMENT.fromLabel')} 3
			</div>
		</div>
	}

	render() {
		return (
			<>
				{this.renderProgressBar()}
				{this.renderProgressBarMobile()}
			</>
		);
	};

}

export default ProgressStep
