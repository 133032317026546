/**/
import React, { Component } from 'react'
import s from './Checkbox.module.css';
import cx from "classnames";
import checkIcon from "../../assets/blueCheck.svg";

class Checkbox extends Component {

	componentDidMount() {
	}

	render() {
		const {checked, onClick} = this.props;
		return <div onClick={onClick} className={cx(s.container)}>
				<input className={s.checkboxHidden} type="checkbox" defaultChecked={checked}  />
				<div className={s.checkbox}>
					{checked && <img src={checkIcon} />}
				</div>
			</div>


	}

}

export default Checkbox
