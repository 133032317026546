import React, { Fragment } from "react";

export const UKRAINIAN = {
  LANG_CODE: "uk",
  COUNTRY_CODE: "uk",
  CURRENCY: {
    symbol: "€",
    name: "ЄВРО",
    id: "0",
    minPaymentAmount: "0.04",
    maxPaymentAmount: "495000",
    billingLabel: "ЄВРО",
    traderKey: "ключ торгівця",
  },
  FOOTER: {
    copyright: "NAKA GLOBAL Ltd.",
    version: "версія",
  },
  LOGIN: {
    username: "Ім'я користувача",
    password: "Пароль",
    usernameEmptyError: "Введіть ім'я користувача",
    passwordEmptyError: "Введіть пароль",
    login: "УВІЙТИ В ОБЛІКОВИЙ ЗАПИС",
    notAuthorized: "Не авторизований",
    hidePassword: "Приховати",
    showPassword: "Показати",
    invalidCredentials: "Неправильне ім'я користувача або пароль",
  },
  HEADER: {
    help: "ДОПОМОГА",
    callAgent: "support@gocrypto.com",
    agentNumber: "",
  },
  SIDEBAR: {
    logout: "Вийти з облікового запису",
    newPayment: "Новий платіж",
    archive: "Архів",
    billing: "Виставлення рахунків",
    faq: "Часті запитання",
  },
  ARCHIVE: {
    overviewTitle: "Перелік отриманих транзакцій",
    refreshLabel: "ОНОВИТИ",
    exportLabel: "ЕКСПОРТУВАТИ",
    tableHeaders: {
      ID: "Ідентифікатор",
      lastUpdatedTime: "ЧАС",
      lastUpdatedDate: "ДАТА",
      lastUpdated: (
        <span>
          ЧАС <span className="mobile-hidden"> ДАТА</span>
        </span>
      ),
      amount: "СУМА",
      combinedAmount: (
        <span>
          VALUE <span className="mobile-hidden"> </span>
        </span>
      ),
      cryptoCurrency: "ВАЛЮТІ",
      cryptoAmount: "СУМА В КРИПТО",
      print: "ДРУК",
      status: "СТАТУС",
      referenceNumber: "ПОСИЛАННЯ",
      revert: "Повернутися",
    },
    export: {
      startDate: "Початкова дата",
      endDate: "Кінцева дата",
      error: "Обрати діапазон дат",
      exportTitle: "Експортувати платежі",
      lastMonth: "Минулого місяця",
      showResults: "ПОКАЗАТИ РЕЗУЛЬТАТИ",
      successfulPayments: "Успішні платежі",
    },
    printLabel: "Друк",
    print: {
      amountLabel: "Сума у валюті",
      transactionInfoLabel: "Інформація про транзакції",
      dateTimeLabel: "Дата та час",
    },
    emptyList: "В архівах немає платежів",
  },
  PAYMENT: {
    stepLabel: "крок",
    fromLabel: "з",
    createPayment: "Створити платіж",
    paymentReady: "Готовий до сплати",
    paymentFinished: "Завершити оплату",
    amount: "Сума оплати",
    amountErrorLow: "Введено надто малу суму",
    amountErrorHigh: "Введено надто велику суму",
    referenceNumberLabel: "Кодовий номер",
    createPaymentButton: "Продовжити для здійснення оплати",
    cancelPaymentButton: "скасувати платіж",
    qrCodeReady: "QR-код готовий для сканування",
    resetPaymentButton: "Новий платіж",
    unknown: "Неможливо створити платіж. Зверніться до нашої служби підтримки.",
    paymentStatus: {
      CRYPTO_PAYMENT_STATUS_OPENED: "ВІДКРИТИ",
      CRYPTO_PAYMENT_STATUS_IN_PAYMENT: "В РАХУНОК ПЛАТИ",
      CRYPTO_PAYMENT_STATUS_PAID: "ВДАЛО",
      CRYPTO_PAYMENT_STATUS_PROCESSING: "ОБРОБЛЕННЯ",
      CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "ПРОТЕРМІНОВАНО",
      CRYPTO_PAYMENT_STATUS_FAILED: "НЕ ВДАЛОСЯ",
      CRYPTO_PAYMENT_STATUS_NOT_VALID: "НЕДІЙСНИЙ",
      CRYPTO_PAYMENT_STATUS_REVERTED: "ПОВЕРНЕНО",
      CRYPTO_PAYMENT_STATUS_CANCELED: "СКАСОВАНО",
      CRYPTO_PAYMENT_STATUS_UNDERPAID: "НЕДОСТАТНІЙ",
    },
    paymentStatusLabel: {
      CRYPTO_PAYMENT_STATUS_OPENED: "QR-код готовий для сканування",
      CRYPTO_PAYMENT_STATUS_PAID: "Оплата пройшла успішно",
      CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "Час здійснення оплати закінчився.",
      CRYPTO_PAYMENT_STATUS_UNDERPAID:
        "Оплата занадто низька.\n Щоб отримати допомогу, зверніться до GoCrypto за адресою: support@gocrypto.com",
    },
    currencyNotice:
      "Сума в євро формується на основі курсу обміну валют, наданого NAKA GLOBAL ltd.",
  },
  EXPORT: {
    exportTransactionsLabel: "ОПЕРАЦІЇ З ЕКСПОРТУВАННЯ",
    timeRangeLabel: "Період",
    fileTypeLabel: "Тип файлу",
    exportLabel: "ЕКСПОРТУВАТИ",
    lastMonth: "Минулого місяця",
    showResults: "Показати результати",
  },
  REPORT: {
    dailyReport: {
      title: "Виставлення рахунків",
      exportLabel: "ЕКСПОРТУВАТИ",
      print: "ДРУК",
      noTransactions: "Немає транзакцій",
      currencyLabel: "Сума в",
      totalLabel: "Всього в",
      back: "НАЗАД",
      amountLabel: "СУМА",
      currencyInLabel: "в",
    },
  },
  ERROR: {
    unknownCause: "Сталася несподівана помилка",
    reload: "Будь ласка, спробуйте спершу перезавантажити/очистити кеш",
    report: "Якщо проблема не зникає, повідомте про неї",
  },
  GOCRYPTO: "",
  TOOLTIPS: {
    PAYMENT: {
      mobile: {
        0: "Використовуйте клавіатуру для введення суми оплати.",
        1: 'Для здійснення оплати, натисніть на кнопку "Продовжити". Відкриється екран для сканування QR-кода.',
      },
      tablet: {
        0: "Використовуйте клавіатуру для введення суми оплати.",
        1: 'Для здійснення оплати, натисніть на кнопку "Продовжити". Відкриється екран для сканування QR-кода.',
      },
      desktop: {
        0: "Використовуйте клавіатуру для введення суми оплати.",
        1: 'Для здійснення оплати, натисніть на кнопку "Продовжити". Відкриється екран для сканування QR-кода.',
      },
    },
    ARCHIVE: {
      mobile: {
        0: "Переглянути платежі за часом створення та статусом.",
        1: "Експортувати платежі за часом та успішністю здійснення операції у формат PDF або CSV.",
      },
      tablet: {
        0: "Переглянути платежі за часом створення та статусом.",
        1: "Експортувати платежі за часом та успішністю здійснення операції у формат PDF або CSV.",
      },
      desktop: {
        0: "Переглянути платежі за часом створення та статусом.",
        1: "Експортувати платежі за часом та успішністю здійснення операції у формат PDF або CSV.",
      },
    },
    next: "ДАЛІ",
  },
  FAQ: {
    title: "webPOS - часті запитання",
    QA: [
      {
        question: "Як створити платіж GoCrypto?",
        answer: (
          <Fragment>
            <p>
              Оберіть "Новий платіж" у головному меню і введіть суму платежу в
              спеціальному полі для введення за допомогою клавіатури або
              сенсорного екрана. За потреби можна додати кодовий номер.
            </p>
            <p>
              Натисніть на кнопку "Створити платіж" і буде згенерований QR-код.
              Попросіть покупця зісканувати цей QR-код або наліпку, яка була
              розміщена на касі, якщо екран не є легкодоступним.
            </p>
            <p>
              Щойно покупець закінчить цей процес, з'явиться зелена стрілка зі
              статусом «Платіж успішний». Це означає, що кошти отримані, і ви
              можете виставити рахунок.{" "}
            </p>
            <p>
              Для потреб бухгалтерського обліку, можна увійти в "Архіви" і
              видрукувати підтвердження.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question: "Термін оплати закінчився. Що це означає?",
        answer: (
          <Fragment>
            <p>
              Коли створюється платіж, з'являється QR-код з таймером. З цього
              моменту користувачу необхідно завершити платіж за 5 хвилин. Якщо
              це займе більше часу, визначений термін завершиться, і вам
              доведеться створити платіж ще раз.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question:
          "Я ввів неправильну суму платежу, а QR-код вже згенерований. Що тепер?",
        answer: (
          <Fragment>
            <p>
              Не хвилюйтеся, просто натисніть на кнопку «Скасувати платіж» і
              повторіть процес ще раз.
            </p>
          </Fragment>
        ),
      },
      {
        question:
          "Де я можу ознайомитися з історією платежів і експортувати їх?",
        answer: (
          <Fragment>
            <p>
              Натисніть на кнопку "Архіви" в головному меню, щоб шляхом
              прокручування переглянути всю історію платежів. Можна
              відфільтрувати результати пошуку за статусом і датою платежу. Для
              потреб бухгалтерського обліку, архіви можна експортувати у формат
              CSV або PDF.
            </p>
          </Fragment>
        ),
      },
      {
        question: "Чи можу я згенерувати щоденні виписки з рахунків?",
        answer: (
          <Fragment>
            <p>
              Авжеж, натисніть на кнопку "Виставлення рахунків" в головному
              меню, де ви можете видрукувати звіт за поточний день, куди
              завантажуються платежі, здійснені в окремо визначених валютах.
            </p>
          </Fragment>
        ),
      },
      {
        question: "Які криптовалюти підтримуються?",
        answer: (
          <Fragment>
            <p>
              Crypto дозволяє здійснювати платежі у 40 криптовалютах. Bitcoin,
              Bitcoin Cash, Ether, the GoC token, Litecoin та the
              Viberate token - це лише деякі з них. Однак, вам не потрібно про
              них знати. Щойно користувач зісканує QR-код, він обере валюту
              платежу у своєму криптогаманці. Процедура для вас буде однакова в
              усіх випадках.{" "}
            </p>
            <p>
              На додаток до криптовалют, в деяких країнах GoCrypto також
              підтримує виплати, здійснені за допомогою раніше депонованих сум в
              євро.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question:
          "Які гаманці покупці можуть використовувати для закінчення здійснення платежу?",
        answer: (
          <Fragment>
            <p>
              Покупці можуть використовувати гаманець Elly, технологію Binance
              Pay (обидва підтримують кілька валют) і Bitcoin.com-гаманець
              (підтримує Bitcoin Cash). Процес абсолютно однаковий для вас у
              всіх випадках.
            </p>
          </Fragment>
        ),
      },
      {
        question: "Чи підтримується оплата в стандартній валюті?",
        answer: (
          <Fragment>
            <p>
              Так, крім криптовалют в деяких регіонах GoCrypto підтримує також
              платежі, здійснені за допомогою раніше депонованих сум в євро.
            </p>
          </Fragment>
        ),
      },
      {
        question: "Мій покупець просить про відшкодування.",
        answer: (
          <Fragment>
            <p>
              Після успішного закінчення здійснення платежу кошти не можна буде
              відшкодувати через систему GoCrypto, тож повернення коштів повинен
              здійснювати продавець.
            </p>
          </Fragment>
        ),
      },
      {
        question:
          "У мене виникли проблеми зі створенням платежу або використанням GoCrypto POS.",
        answer: (
          <Fragment>
            <p>
              {" "}
              Будь ласка, зателефонуйте нашому агенту, доступному за номером
              +386 51 647 071. Ви також можете надіслати електронний лист на{" "}
              <a href="mailto:support@gocrypto.com">
                support@gocrypto.com
              </a>.{" "}
            </p>
          </Fragment>
        ),
      },
    ],
  },
};
