import { apiClient } from "./util";
import { AxiosPromise } from "axios";

export default {
  getSupportedLanguageList: (): AxiosPromise =>
    apiClient.get("/languages?app=gocrypto_pos"),
  setPOSLanguage: (langCode): AxiosPromise => {
    const requestBody = {
      language: langCode,
    };

    return apiClient.post("/language/pos", requestBody);
  },
};
