/**/
import React, { Component } from 'react'
import s from './ExportModal.module.css'
import {Modal} from "reactstrap";
import ModalBody from "reactstrap/es/ModalBody";
import TransparentButton from "../ThemeButton/TransparentButton";
import closeIcon from "../../assets/closeIcon.svg";
import YellowButton from "../ThemeButton/YellowButton";
import {DateRangePicker} from "react-dates";
import calendarIcon from "../../assets/blackCalendarIcon.svg";
import cx from "classnames";
import t from "../../translation/translation";
import exportIcon from "../../assets/blackExportIcon.svg";


class ExportModal extends Component {

	componentDidMount() {
	}

	render() {
		const {isOpen, toggle, startDate, endDate, onExport} = this.props;
		return <Modal size={"lg"} toggle={toggle} isOpen={isOpen} modalClassName={s.modalWrapper} contentClassName={s.modalContent}>
			<ModalBody className={s.modalBody}>
				<div className={s.form}>
					<div className={s.header}>
						<div><h2 className={s.title}>{t('ARCHIVE.export.exportTitle')}</h2></div>
						<TransparentButton className={s.close} onClick={toggle}><img className={s.closeIcon} src={closeIcon} /></TransparentButton>
					</div>
					<div className={cx(s.dateWrapper, "disabled")}>
						<DateRangePicker
							startDate={startDate}
							customArrowIcon={<div className={"divider"}></div>}
							customInputIcon={<div className={"d-flex"}><img src={calendarIcon} /></div>}
							endDate={endDate}
							startDatePlaceholderText={t("ARCHIVE.export.startDate")}
							endDatePlaceholderText={t("ARCHIVE.export.endDate")}
							onDatesChange={() => {}}
							onFocusChange={() => {}}
							startDateId="your_unique_start_date_id"
							endDateId="your_unique_end_date_id"
							displayFormat={"DD. MMM YYYY"}
							disabled={true}
						/>
						<div className={cx(s.label, s.from)}>{t("ARCHIVE.export.startDate")}</div>
						<div className={cx(s.label, s.to)}>{t("ARCHIVE.export.endDate")}</div>
					</div>
				</div>

				<div className={s.buttonRow}>
					<YellowButton onClick={onExport(1)} className={s.submitButton}><img className={s.icon} src={exportIcon} />PDF</YellowButton>
					<div className={s.divider}></div>
					<YellowButton onClick={onExport(0)} className={s.submitButton}><img className={s.icon} src={exportIcon} />CSV</YellowButton>
				</div>

			</ModalBody>
		</Modal>

	}

}

export default ExportModal
