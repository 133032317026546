import React from "react";
import worldIcon from "../../assets/world.svg";
import arrowRight from "../../assets/gray-arrow-right.svg";
import css from "./LangPicker.module.css";
import { getLanguage } from "../../translation/translation";
import { connect } from "react-redux";
import cx from "classnames";
import { getSafe } from "../../utils/state";
import language from "../../api/language";
import { bindActionCreators } from "redux";
import { setLanguage } from "../../redux/language";

class LangPicker extends React.Component {
  state = {
    isOpen: false,
  };

  toggle = () => () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  setLanguage = (langCode) => async () => {
    if (langCode === getLanguage().LANG_CODE.toUpperCase()) return;
    try {
      await language.setPOSLanguage(langCode);
      this.props.setLanguage(langCode.toLowerCase(), true);
      window.location.reload();
    } catch (e) {}
  };

  renderLanguage = (language) => {
    return (
      <div
        key={language.code}
        onClick={this.setLanguage(language.code)}
        className={cx(css.option, {
          "font-weight-bold":
            language.code === getLanguage().LANG_CODE.toUpperCase(),
        })}
      >
        {language.native}
      </div>
    );
  };

  render() {
    const { isOpen } = this.state;
    const { supportedLanguages } = this.props;
    const top = 32 + getSafe(supportedLanguages, []).length * 24;
    return (
      <div
        className={css.wrapper}
        onClick={this.toggle()}
        onBlur={this.toggle()}
      >
        <img className={css.worldIcon} src={worldIcon} />
        <span className={css.langCode} key={"langcode"}>
          {getLanguage().LANG_CODE}
        </span>

        <img
          className={cx(css.arrowRight, { [css.rotate]: isOpen })}
          src={arrowRight}
        />
        {isOpen && (
          <div className={css.dropdown} style={{ top: `-${top}px` }}>
            {supportedLanguages.map((language) =>
              this.renderLanguage(language)
            )}
          </div>
        )}
        {isOpen && <div className={css.outside}></div>}
      </div>
    );
  }
}

function mapState(state) {
  return {
    supportedLanguages: state.supportedLanguages.data,
    user: state.user,
  };
}

function mapActions(dispatch) {
  return bindActionCreators({ setLanguage }, dispatch);
}

export default connect(mapState, mapActions)(LangPicker);
