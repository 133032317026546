/**/
import React, { Component } from 'react'
import s from './CurrencyNotice.module.css'
import t, {isCurrencyEuro} from "../../translation/translation";
import moment from "moment";

class CurrencyNotice extends Component {

	render() {
		const {step} = this.props;
		const isEuro = isCurrencyEuro();
		return (!isEuro) && <div className={s.notice}>
			<div className="mr-1">*</div>
			{t('PAYMENT.currencyNotice')} {moment().format("DD.MM.YYYY")}
		</div>
	}

}

export default CurrencyNotice
