import { supportedLanguages } from "./language";
import { getSafe, getSafeDeep } from "../utils/state";
import { SLOVENIAN } from "./sl";
import { IS_GOCRYPTO, MAX_EURO_PAYMENT } from "../config";

export const LANG_STORAGE_KEY = 'ElipayPOS/Language';
export const LANGCODE_STORAGE_KEY = "ElipayPos/LanguageCode";
export const CURRENCY_STORAGE_KEY = 'ElipayPos/Currency';

export default function t(key) {
    const language = getSafe(supportedLanguages[localStorage.getItem(LANGCODE_STORAGE_KEY)], 'sl');
    return getSafeDeep(language.translation, key, getSafeDeep(SLOVENIAN, key, ""));
}

export const isCurrencyEuro = () => {
    return getCurrencyId() == 0;
};

export const getLanguage = () => {
    const language = getSafe(supportedLanguages[localStorage.getItem(LANGCODE_STORAGE_KEY)], supportedLanguages.sl);
    return language.translation;
};

export const getCurrencyId = () => {
    return getCurrency().id;
};

//formatting Comma Dat => 1,000.00 Antigua and Barbuda
//formatting Dot Comma => 1.000,00 DE
//display_name property in currency
const commaDotCurrencies = [
    'XCD',
    'GHS',
    'KES',
    'NGN',
    'MXN',
    'INR',
    'ILS',
    'JPY',
    'MYR',
    'KRW',
    'LKR',
    'THB',
    'GBP',
    'AUD',
    'NZD',
]

export const getCurrency = () => {
    let curr = JSON.parse(localStorage.getItem(CURRENCY_STORAGE_KEY));
    if (!curr) return null;

    if (commaDotCurrencies.includes(curr.display_name)) {
        curr.parse = parseCommaDot
    } else {
        curr.parse = parseDotComma
    }
    return curr;
};

export const getFraction = () => {
    return getThousands() == ',' ? '.' : ','
}

export const getThousands = () => {
    return getCurrency().parse(0, 2).replace(/0/gi, '') == '.' ? ',' : '.'
}

export const parseCommaDot = (number, toFixed = 0) => {
    return parseFloat(number).toLocaleString('en-US', { minimumFractionDigits: toFixed })
}

export const parseDotComma = (number, toFixed = 0) => {
    return parseFloat(number).toLocaleString('de-DE', { minimumFractionDigits: toFixed })
}

export const isGoCrypto = () => {
    const language = getSafe(supportedLanguages[localStorage.getItem(LANGCODE_STORAGE_KEY)], 'sl');
    return getSafeDeep(language.translation, "GOCRYPTO", false) || IS_GOCRYPTO;
};

export const getSourceConfig = (data) => {
    return getSafeDeep(data, "source.0.config", {});
};

export const getSourceLanguage = (data) => {
    return getSourceConfig(data).language.toLowerCase();
};

export const getSourceCurrency = (data) => {
    return getSourceConfig(data).currency;
};
