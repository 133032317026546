
import {apiClient} from './util';
import {AxiosPromise} from 'axios';
import * as qs from "qs";
import {API_URL} from "../config";
import moment from "moment";
import {removeDoubleSlash} from "../util";
import {getSafeDeep} from "../utils/state";
import {getLanguage} from "../translation/translation";


export default {
    getReports: (): AxiosPromise => apiClient.post("/payment/crypto/pos/report",{
        begin: moment().set({hour:0,minute:0,second:0}).format(),
        end: moment().set({hour:23,minute:59,second:59}).format()
    }),
    exportReports: (type, startDate, endDate): AxiosPromise => apiClient.get(`/payment/crypto/pos/report/export`,{
        report_type: type,
        begin: startDate,
        end: endDate
    }),
    getExportReportsUrl: (type, startDate, endDate, successful): AxiosPromise => {
        const params = {
            report_type: type,
            begin: moment(startDate).format("YYYY-MM-DD"),
            end: moment(endDate).format("YYYY-MM-DD"),
            successful,
            lng_code: getSafeDeep(getLanguage(), 'LANG_CODE', 'en')
        };

        return removeDoubleSlash(`${API_URL}/payment/crypto/pos/report/export?${qs.stringify(params)}`);
    },
    getDailyReportUrl: (): AxiosPromise => {
        const params = {
            lng_code: getSafeDeep(JSON.parse(localStorage.getItem('Elipay-POS')), 'language.code', 'en')
        };
        return removeDoubleSlash(`${API_URL}/payment/crypto/pos/report?${qs.stringify(params)}`);
    }
};
