import React from 'react';
import s from "./PaymentKeyboard.module.css";
import deleteIcon from "../../assets/backspaceSmall.svg";
import KeyboardButton from "../KeyboardButton/KeyboardButton";
import t, { getFraction } from "../../translation/translation";
import DarkThemedButton from "../ThemeButton/DarkThemeButton";
import YellowButton from "../ThemeButton/YellowButton";
import POSTooltip from "../POSTooltip/POSTooltip";

// Set prop types
type PaymentKeyboardProps = {};


const rows = [3, 6, 9, 12];

class PaymentKeyboard extends React.Component<PaymentKeyboardProps> {

	state = {
		buttons: [
			{ value: "1", },
			{ value: "2", },
			{ value: "3", },
			{ value: "4", },
			{ value: "5", },
			{ value: "6", },
			{ value: "7", },
			{ value: "8", },
			{ value: "9", },
			{ value: getFraction() },
			{ value: "0", },
			{ value: "delete", icon: deleteIcon },
		]
	}

	handleSubmit = () => () => {
		this.props.tooltip.nextStep()();
		this.props.handlePaymentSubmit();
	};

	handleButtonClick = () => (e) => {
		this.props.tooltip.nextStep()();
		this.props.handleButtonClick(e);
	};

	render() {
		const { handleButtonClick, handlePaymentSubmit: onSubmit, submitButtonDisabled: disabled, step, tooltip } = this.props;
		if (step !== 0) return <></>;
		return (
			<div className={s.wrapper}>
				<div className={"position-relative"}>
					{rows.map((row, rowIndex) => {
						return <div key={row} className="d-flex justify-content-between">
							{this.state.buttons.filter((button, buttonIndex) => buttonIndex < row && (rowIndex == 0 || buttonIndex >= rows[rowIndex - 1])).map(button => {
								return <KeyboardButton onClick={this.handleButtonClick()} key={button.value} data={button} />
							})}
						</div>
					})}
					<POSTooltip tooltip={tooltip} tooltipStep={0} />
				</div>
				<div className={"position-relative"}>
					<YellowButton disabled={disabled} onClick={this.handleSubmit()} className={s.nextButton}>
						{t('PAYMENT.createPaymentButton')}
					</YellowButton>
					<POSTooltip tooltip={tooltip} tooltipStep={1} />
				</div>


			</div>
		);
	};

}

// Set default props
PaymentKeyboard.defaultProps = {};

export default PaymentKeyboard;
