import React from "react";
import * as cx from "classnames";
import {Button} from "reactstrap";
import s from "./ThemedButton.module.css";


class LightThemeButton extends React.Component<ThemedButtonProps> {

    render() {
        let color = this.props.color;
        const lightBlue =  !color || color == "lightBlue";
        const active = this.props.active;
        return (
            <Button {...this.props}
                    outline={this.props.outline}
                    className={cx(this.props.className, s.button, {[s.lightBlue]:lightBlue }, {[s.active]: active})}
                    onClick={this.props.onClick}>
                { this.props.children }
            </Button>
        );
    };

}

export default LightThemeButton;
