import { getSafeDeep } from "./state";
import { getCurrency, getFraction } from "../translation/translation";

export function commaToDot(number) {
    // if (getDecimalSeparator() === '.') {
    //     //     return number.replace(",", ".");
    //     // }
    //     // if (getDecimalSeparator() === ',') {
    //     //     return number.replace(".", ",");
    //     // }

    return number.replace(",", ".");

}


const allowedValues = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
export function isProperlyFormatted(newAmount) {
    //can contain only numbers
    for (let i = 0; i < newAmount.length; i++) {
        if (allowedValues.find(item => item == newAmount[i]) === undefined) return false;
    }
    //count fractions
    const fractionCount = newAmount.split('').reduce((acc, curr) => {
        if (curr == '.') return acc + 1;
        return acc;
    }, 0)
    //more than one fraction
    if (fractionCount > 1) return false;
    //more than 2 numbers after decimal
    if (fractionCount == 1) {
        if (newAmount.split('.')[1].length > 2) return false
    }
    //million
    const numberLength = newAmount.split('.')[0].length;
    if (numberLength > 7) return false;

    return true;
}


export function formatNumberPayment(number) {
    let newNumber = number;
    const numberCommas = newNumber.split('.').length - 1;
    if (numberCommas > 1) {
        if (newNumber[newNumber.length - 1] === '.') newNumber = newNumber.slice(0, newNumber.length - 1);
    }
    if (numberCommas === 1) {
        //if (newNumber[newNumber.length - 1] === '.') newNumber = `${newNumber}0`;
    }
    const decimal = getSafeDeep(number.split('.'), "1", undefined);
    if (decimal !== undefined && decimal.length > 2) {
        newNumber = newNumber.slice(0, newNumber.length - 1);
    }
    return newNumber;
}

function stripLeadingZeros(number) {
    if (number[0] !== '0') return number;
    let integer = number.split(getDecimalSeparator())[0];
    if (integer.length === 1) return number;
    return number.replace(/^0+/, '')
}

export function formatNumberPaymentSafe(number) {
    let newNumber = number;
    let decimal = number.split(getDecimalSeparator()).length > 1 ? number.split(getDecimalSeparator())[1] : "";
    if (decimal.length > 2) {
        newNumber = `${number.split(getDecimalSeparator())[0]}${getDecimalSeparator()}${decimal.substr(0, 2)}`;
    }
    return newNumber;
}

export function getDecimalSeparator() {
    return ".";
    //fallback
    var decSep = ".";

    try {
        // this works in FF, Chrome, IE, Safari and Opera
        var sep = parseFloat(3 / 2).toLocaleString().substring(1, 2);
        if (sep === '.' || sep === ',') {
            decSep = sep;
        }
    } catch (e) { }

    return decSep;
}

export function isProperlyFormattedNumber(number) {
    const numberCommas = number.split('.').length - 1;
    if (numberCommas > 1) return false;


    const decimal = getSafeDeep(number.split('.'), "1", undefined);
    if (decimal !== undefined && decimal.length > 2) {
        return false;
    }

    return true;
}

export function secondsToMinutes(seconds) {
    const minutes = parseInt(seconds / 60);
    let secondsLeft = parseInt(seconds % 60);
    secondsLeft = `${secondsLeft}`;
    if (secondsLeft < 10) secondsLeft = `0${secondsLeft}`;

    return `${minutes}:` + secondsLeft;
}
