import { initState } from "./util";
import {
  LANG_STORAGE_KEY,
  LANGCODE_STORAGE_KEY,
} from "../translation/translation";
import { getSafe, getSafeDeep } from "../utils/state";
import { supportedLanguages } from "../translation/language";
import * as moment from "moment";
import "moment/locale/sl";
import "moment/locale/hr";
import "moment/locale/tr";
import "moment/locale/es";
import "moment/locale/ja";
import "moment/locale/ru";
import "moment/locale/hu";
import "moment/locale/sk";
import "moment/locale/pt";
import "moment/locale/it";
import "moment/locale/uk";

//const
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

export default function (state = initState, action) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      localStorage.setItem(
        LANG_STORAGE_KEY,
        JSON.stringify({ language: action.payload.language })
      );
      localStorage.setItem(LANGCODE_STORAGE_KEY, action.payload.language.code);
      if (!action.payload.silent) {
        moment.locale(
          getSafeDeep(
            supportedLanguages[action.payload.language.code],
            "translation.LANG_CODE",
            "en"
          )
        );
        return { ...state, language: action.payload.language };
      } else {
        return state;
      }

    default:
      return state;
  }
}

//action
export const setLanguage = (langKey, silent = false) => ({
  type: CHANGE_LANGUAGE,
  payload: {
    language: getSafe(supportedLanguages[langKey], supportedLanguages.si),
    silent: silent,
  },
});
