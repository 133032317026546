import React from 'react';
import css from "./footer.module.css";
import * as classnames from "classnames";
import t from "../../translation/translation";
import packageInfo from "../../../package.json";

import LangPicker from "../LangPicker/LangPicker";

// Set prop types
type FooterProps = {};

class Footer extends React.Component<FooterProps> {
    render() {
        const {borderless} = this.props;
        return (
            <div className={classnames(css.footer, {"container": !borderless}, {[css.footerDesktop]: !borderless})}>
                <div>
                    © {t('FOOTER.copyright')} | {t('FOOTER.version')} {packageInfo.version}
                </div>
                {!borderless && <LangPicker />}
            </div>
        );
    };
}
// Set default props
Footer.defaultProps = {};

export default Footer;
