import {Button} from "reactstrap";
import * as classnames from "classnames";
import css from "./ThemedButton.module.css";
import React from "react";

export default class TransparentButton extends React.Component {
    render() {
        let {color, lg, outline} = this.props;

        return (
            <Button {...this.props}
                    className={classnames(css.transparentButton, {[css.largeButton]: lg}, {[css.transparentOutline]: outline}, this.props.className)}
                    onClick={this.props.onClick}>
                { this.props.children }
            </Button>
        );
    };
}

