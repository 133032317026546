import {fulfilled, fulfilledState, initState, pending, pendingState, rejected, rejectedState} from "./util";

import auth from "../api/auth";
import {getSourceLanguage, LANG_STORAGE_KEY, LANGCODE_STORAGE_KEY} from "../translation/translation";

export const GET_USER = "GET_USER";
export const CLEAR_USER = "CLEAR_USER";

export default function (state = initState, action) {
    switch (action.type) {
        case pending(GET_USER):
            return pendingState(state);
        case fulfilled(GET_USER):
            localStorage.setItem(LANGCODE_STORAGE_KEY, getSourceLanguage(action.payload.data));
            return fulfilledState(state, action.payload.data);
        case rejected(GET_USER):
            return rejectedState(state, action.payload);
        case CLEAR_USER:
            return initState;
        default:
            return state;
    }
}

export const getUser = () => ({
    type: GET_USER,
    payload: auth.getUser()
});

export const clearUser = () => ({
    type: CLEAR_USER
});
