/**/
import React, { Component } from 'react';
import s from './HoveringInputField.module.css';
import cx from 'classnames';
import {getSafeDeep} from "../../utils/state";

class HoveringInputField extends Component {

	constructor(props) {
		super(props);
		this.state = {
			inputFocused: false
		};
		this.inputRef = React.createRef();
	}

	setInputFocus = (value) => () => {
		this.setState({inputFocused: value});
	};

	render() {

		const {prefix, placeholder, type, error, onChange, value, name, disabled, style, maxLength} = this.props;
		const {inputFocused} = this.state;

		return <div className={s.container} >
			<div className={s.prefix}>{prefix}</div>
			{error && <div className={cx(s.error, s.hover)}>{error}</div>}
			{!error && <div style={style} className={cx(s.placeholder, {[s.hover]: inputFocused || getSafeDeep(this.inputRef, "current.value", false)})}>{placeholder}</div>}
			<input onBlur={this.setInputFocus(false)}
				   onFocus={this.setInputFocus(true)}
				   onChange={onChange}
				   style={style}
				   value={value}
				   ref={this.inputRef}
				   name={name}
				   disabled={disabled}
				   maxLength={maxLength}
				   type={type}
				   className={s.input} />
		</div>

	}

}

export default HoveringInputField
