import activePaymentIcon from "./assets/New_payment_icon.svg";
import paymentIcon from "./assets/New_payment_passive.svg";
import billingIcon from "./assets/Billing_icon.svg";
import activeBillingIcon from "./assets/noun_Document_504765.svg";
import archiveIcon from "./assets/Archive_icon.svg";
import activeArchiveIcon from "./assets/Icon-archive-active.svg";
import faqIcon from "./assets/faq-icon.svg";
import checkIcon from "./assets/Element_Icon_Message.svg";
import clockIcon from "./assets/noun_clock_2593810.svg";
import activeFaqIcon from "./assets/faq-icon-mobile-active.svg";
import mobileCheckIcon from "./assets/Success_mobile.svg";
import { isCurrencyEuro, getCurrency } from "./translation/translation";
import css from "./views/Report/report.module.css";
import React from "react";

function setPropRecurse(obj, fields, value) {
    if (fields.length === 1) {
        obj[fields[0]] = value;
        return;
    }
    const prop = fields.shift();
    if (!obj[prop]) {
        if (parseInt(prop, 10) >= 0) {
            obj[prop] = [];
        } else {
            obj[prop] = {};
        }
    }
    return setPropRecurse(obj[prop], fields, value);
}

export function renderTotal(data) {
    const key = isCurrencyEuro() ? "amount" : "equivalent_amount";
    return <div className={css["textBold"]}>
        {formatDecimals(data.reduce((accumulator, currentValue) => accumulator + currentValue[key], 0), 2)}
    </div>
}

export function setProp(obj, prop, value) {
    const props = prop.split(".");
    return getPropRecurse(obj, props, value)
}

export const getSafe = (obj, def = "") => obj !== null && obj !== undefined ? obj : def;

function getPropRecurse(obj, props) {
    if (props.length === 1) {
        return obj[props[0]];
    }
    const prop = props.shift();
    return getPropRecurse(getSafe(obj[prop], {}), props);
}

export function getProp(obj, prop) {
    const props = prop.split(".");
    return getPropRecurse(obj, props);
}

export function formatDecimals(number, decimalPlaces) {
    // return parseFloat(number).toFixed(decimalPlaces);
    return getCurrency().parse(number, decimalPlaces)
}

export function removeDoubleSlash(string) {
    return string.replace(/([^:]\/)\/+/g, "$1");
}

export function padDigits(number, digits) {
    return Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number;
}

export function commaToDot(number) {
    return number.replace(",", ".");
}

export function secondsToMinutes(seconds) {
    const minutes = parseInt(seconds / 60);
    let secondsLeft = parseInt(seconds % 60);
    secondsLeft = `${secondsLeft}`;
    if (secondsLeft < 10) secondsLeft = `0${secondsLeft}`;

    return `${minutes}:` + secondsLeft;
}

const allowedValues = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ','];
export function isProperlyFormatted(number) {
    for (let i = 0; i < number.length; i++) {
        if (allowedValues.find(item => item == number[i]) === undefined) return false;
    }
    const numberCommas = number.split(',').length - 1;
    if (numberCommas > 1) return false;

    if (numberCommas == 1) {
        const decimalPlaces = number.split(',')[1].length;
        if (decimalPlaces > 2) return false;
    }
    return true;
}

export const routes = {
    payment: {
        path: "/pos/payment/",
        exact: true,
        authenticated: true,
        label: "Novo plačilo",
        key: "newPayment",
        icon: paymentIcon,
        activeIcon: activePaymentIcon
    },
    transactions: {
        path: "/pos/transactions/",
        exact: true,
        authenticated: true,
        label: "Arhiv plačil",
        key: "archive",
        icon: archiveIcon,
        activeIcon: activeArchiveIcon
    },
    report: {
        path: "/pos/report/",
        label: "Obračun",
        key: "billing",
        icon: billingIcon,
        activeIcon: activeBillingIcon,
        exact: true,
        authenticated: true,
    },
    settings: { path: "/pos/settings/" },
    login: {
        path: "/pos/login/",
        regex: "/pos/login/:lang(en|si|hr|tr)?"
    },
    confirm: { path: "/pos/registration/confirm/" },
    reset: { path: "/pos/reset/confirm/" },
    help: {
        path: "/pos/help/",
        label: "FAQ",
        icon: faqIcon,
        key: "faq",
        activeIcon: activeFaqIcon,
        exact: true,
        authenticated: true,
    },
    redirect: { path: "/pos/redirect/" },
    kycWrongDevice: { path: "/pos/kyc/wrong-device/" },
    registrationWrongDevice: { path: "/pos/confirm-registration/wrong-device/" },
    passwordWrongDevice: { path: "/pos/reset-password/wrong-device/" },
    changePasswordConfirm: { path: "/pos/change-password/confirm/" },
    changePasswordLocked: { path: "/pos/change-password/locked/" },
    changePasswordMisclick: { path: "/pos/change-password/misclick/" },
    unsubscribeSuccessfull: { path: "/pos/unsubscribe/" }
};

export const paymentStatusTypes = {
    2: {
        type: "CRYPTO_PAYMENT_STATUS_PAID",
        statusId: 2,
        cssClass: "green",
        icon: checkIcon,
        mobileIcon: mobileCheckIcon
    },
    4: {
        type: "CRYPTO_PAYMENT_STATUS_AUTO_CLOSED",
        statusId: 4,
        cssClass: "red",
        amountStatus: "rgb(244, 245, 250)",
        icon: clockIcon,
        mobileIcon: clockIcon
    },
    5: {
        type: "CRYPTO_PAYMENT_STATUS_FAILED",
        statusId: 5,
        cssClass: "red",
        amountStatus: "rgb(244, 245, 250)",
        icon: clockIcon,
        mobileIcon: clockIcon
    },
    9: {
        type: "CRYPTO_PAYMENT_STATUS_UNDERPAID",
        statusId: 9,
        cssClass: "red",
        amountStatus: "rgb(244, 245, 250)",
        icon: clockIcon,
        mobileIcon: clockIcon
    }
};

