import {fulfilled, fulfilledState, initState, pending, pendingState, rejected, rejectedState} from "./util";
import revert from "../api/revert";

export const GET_REVERT_PERMISSION = "GET_REVERT_PERMISSION";

export default function (state = initState, action) {
    switch (action.type) {
        case pending(GET_REVERT_PERMISSION):
            return pendingState(state);
        case fulfilled(GET_REVERT_PERMISSION):
            return fulfilledState(state, action.payload.data);
        case rejected(GET_REVERT_PERMISSION):
            return rejectedState(state, action.payload);
        default:
            return state;
    }
}

export const getRevertPermission = () => ({
    type: GET_REVERT_PERMISSION,
    payload: revert.getRevertPermission()
});
