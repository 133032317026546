import { Button } from "reactstrap";
import React from "react";
import '../components/DataTable/dataTable.module.css';
import moment from "moment";
import { getSafe, getSafeDeep } from "./state";
import { padDigits } from "../util";
import successIcon from "../assets/check.png";
import failedIcon from "../assets/cross.png";
import timeoutIcon from "../assets/loading.png";
import { supportedCurrencies } from "../translation/currencies";
import { getCurrencyId } from "../translation/translation";

export const renderId = (row, instance) => {
    let buttonClass = 'danger';
    if (instance.props.statuses) {
        if (row.status < 4) {
            buttonClass = 'gray';
        }
        if (row.status == 2) {
            buttonClass = 'success';
        }
    }
    return <span className="d-print-none" styleName={``}>{padDigits(row.id, 4)}</span>
};

export const renderReference = (row, instance) => {
    return <span styleName="reference" title={row.reference_number}>{getSafeDeep(row, "reference_number", "")}</span>
};

export const renderStatusIcon = (row, instance, language) => {
    if (instance.props.statuses) {
        let icon = undefined;
        switch (row.status) {
            case 2:
                icon = successIcon;
                break;
            case 4:
                icon = timeoutIcon;
                break;
            case 5:
                icon = failedIcon;
                break;
            default:
                icon = undefined;
        }
        if (icon) {
            return <img src={icon} styleName="status" />
        }
    }
};

export const renderStatus = (row, instance, language) => {
    if (instance.props.statuses) {

        const statusMap = {
            1: 'blue',
            2: 'green',
            3: 'blue',
            4: 'red',
            5: 'red',
            6: 'red',
            7: 'orange',
            8: 'red',
            9: 'red'
        };

        const buttonClass = `status-${statusMap[row.status]}`;


        return <div styleName={`button-status ${buttonClass}`}>{getSafeDeep(language.PAYMENT.paymentStatus, STATUS_NAME_MAP[row.status])}</div>;
    }

};

export const renderReportAmount = (row, instance, useSymbol = false) => {
    let decimalPlaces = 2;
    let currency = 0;
    if (instance.props.currencies) {
        currency = instance.props.currencies.find((item) => item.display_name == "EUR");
    }
    if (currency) {
        decimalPlaces = currency.decimal_places;
    }
    let amount = row.amount;
    let currencyId = 0;
    if (row.equivalent_amount) {
        amount = row.equivalent_amount;
        currencyId = getCurrencyId();
    }
    let parsed = parseFloat(amount).toFixed(decimalPlaces);
    if (useSymbol) return <span>{parsed.replace(".", ",")} </span>
    return <span>{parsed} </span>;
};

export const renderCombinedAmount = (row, instance) => {
    return <div className="d-flex flex-column">
        <span className="font-weight-bold">{renderAmount(row, instance, true)}</span>
        <span className="mobile-hidden">{renderCryptoAmount(row, instance, true)}</span>
    </div>
};

export const renderAmount = (row, instance, useSymbol = false) => {
    let decimalPlaces = 2;
    let currency = 0;
    if (instance.props.currencies) currency = instance.props.currencies.find((item) => item.currency == "EUR");
    if (currency) decimalPlaces = currency.decimalPlaces;

    let amount = row.amount;
    let currencyId = 0;
    if (getSafeDeep(row, "extra.equivalent_value.amount", undefined)) {
        amount = getSafeDeep(row, "extra.equivalent_value.amount");
        currencyId = getSafeDeep(row, "extra.equivalent_value.currency_id");
    }

    //amount = getSafeDeep(instance.props.language, "CURRENCY.id") == 0 ? row.amount : getSafeDeep(row, "extra.equivalent_value.amount", undefined);
    //if(amount === undefined) amount = row.amount;
    let parsed = parseFloat(amount).toFixed(decimalPlaces);
    if (useSymbol) return <span>{parsed.replace(".", ",")} <span className="font-weight-bold ">{getSafeDeep(supportedCurrencies, `${currencyId}.name`)}</span></span>
    return <span className="font-weight-bold">{parsed} <span className="">{getSafeDeep(supportedCurrencies, `${currencyId}.name`)}</span></span>;

};

export const renderCryptoAmount = (row, instance, comma = false) => {
    if (!row.crypto_currency) return "";
    let decimalPlaces = 8;
    let currency = 0;
    if (instance.props.currencies) currency = instance.props.currencies.find((item) => item.id == row.crypto_currency);
    if (currency) decimalPlaces = currency.decimal_places;
    if (comma) return <span>{parseFloat(row.crypto_amount).toFixed(decimalPlaces).replace(".", ",")} <span className="">{renderCurrencyAbbr(row, instance)}</span></span>;
    return <span>{parseFloat(row.crypto_amount).toFixed(decimalPlaces)} <span className="">{renderCurrencyAbbr(row, instance)}</span></span>;

};

export const renderCurrency = (row, instance) => {
    if (instance.props.currencies) {
        if (row.crypto_currency) return instance.props.currencies.find((item) => item.id == row.crypto_currency).name.split("-")[0]
            + " " + renderCurrencyAbbr(row, instance);
        else return instance.props.currencies.find((item) => item.id == row.currency).name + " " + renderCurrencyAbbr(row, instance);
    }
};

export const renderCurrencyName = (row, instance) => {
    if (instance.props.currencies) {
        if (row.crypto_currency) return instance.props.currencies.find((item) => item.id == row.crypto_currency).name.split("-")[0];
        else return instance.props.currencies.find((item) => item.id == row.currency).name;
    }
};

export const renderCurrencyAbbr = (row, instance) => {
    if (instance.props.currencies) {
        if (row.crypto_currency) return instance.props.currencies.find((item) => item.id == row.crypto_currency).display_name;
        else return instance.props.currencies.find((item) => item.id == row.currency).display_name;
    }
};

export const renderTimeOnly = (row, instance, isPrint = false) => {
    let timestamp = moment(row.last_updated).format('HH:mm');
    if (isPrint) {
        timestamp = moment(row.last_updated).format('HH:mm');
        return <span><span>{timestamp.split(" ")[0]}</span><span className="ml-1">{timestamp.split(" ")[1]}</span></span>
    }
    return <span className="d-flex flex-column" styleName={` `}><span className="">{timestamp.split(" ")[0]}</span><span className="font-weight-bold ">{timestamp.split(" ")[1]}</span></span>

};

export const renderDateOnly = (row, instance, isPrint = false) => {
    let timestamp = moment(row.last_updated).format('DD.MM.YYYY');
    if (isPrint) {
        timestamp = moment(row.last_updated).format('DD.MM.YYYY');
        return <span><span>{timestamp.split(" ")[0]}</span><span className="ml-1">{timestamp.split(" ")[1]}</span></span>
    }
    return <span className="d-flex flex-column" styleName={``}><span className="">{timestamp.split(" ")[0]}</span><span className="font-weight-bold ">{timestamp.split(" ")[1]}</span></span>

};


export const renderTime = (row, instance, isPrint = false) => {
    let buttonClass = 'danger';
    if (instance.props.statuses) {
        if (row.status < 4) {
            buttonClass = 'gray';
        }
        if (row.status == 2) {
            buttonClass = 'success';
        }
    }
    let timestamp = moment(row.last_updated).format('DD.MM HH:mm');
    let year = moment(row.last_updated).format('YYYY');
    if (isPrint) {
        timestamp = moment(row.last_updated).format('DD.MM.YYYY, HH:mm');
        return <span><span>{timestamp.split(" ")[0]}</span><span className="ml-1">{timestamp.split(" ")[1]}</span></span>
    }
    return <span className="d-flex flex-column w-100-px" styleName={` ${buttonClass}`}><span className=" ">{timestamp.split(" ")[1]}</span><span className="">{timestamp.split(" ")[0]} <span className="mobile-hidden year">.{year}</span></span></span>
};

export function stripDiacriticalLetters(string) {
    const diacriticals = {
        "č": "c",
        "Č": "C",
        "ž": "z",
        "Ž": "Z",
        "š": "s",
        "Š": "S",
        "ć": "c",
        "Ć": "C",
    };
    for (let letter in diacriticals) {
        string = string.replace(letter, diacriticals[letter]);
    }
    return string;
}


export const STATUS_NAME_MAP = {
    0: "CRYPTO_PAYMENT_STATUS_OPENED",
    1: "CRYPTO_PAYMENT_STATUS_IN_PAYMENT",
    2: "CRYPTO_PAYMENT_STATUS_PAID",
    3: "CRYPTO_PAYMENT_STATUS_PROCESSING",
    4: "CRYPTO_PAYMENT_STATUS_AUTO_CLOSED",
    5: "CRYPTO_PAYMENT_STATUS_FAILED",
    6: "CRYPTO_PAYMENT_STATUS_NOT_VALID",
    7: "CRYPTO_PAYMENT_STATUS_REVERTED",
    8: "CRYPTO_PAYMENT_STATUS_CANCELED",
    9: "CRYPTO_PAYMENT_STATUS_UNDERPAID"
};
