/**/
import React, { Component } from 'react'
import s from './AppLoader.module.css';
import logo from "../../assets/GoCryptoLogoOnly.svg";
import cx from "classnames";

const DEFAULT_TIMER = 10000;
const TIMER_TICK = 500;

class AppLoader extends Component {

	state = {
		width: 0,
		isVisible: true
	};

	renderLoader() {
		const {isInitial} = this.props;
		return <>
			<div key={"wrapper"} className={cx(s.wrapper, {[s.invisible]: !this.props.isLoading })}>
				<div className={s.container}>
					{ !isInitial && <img className={s.logo} src={logo} /> }
					<div className={s.loadingBar}>
						<div className={s.progressBar} style={{ width: `${this.state.width}%`}}></div>
					</div>
				</div>
			</div>
		</>
	}

	startTimer() {
		const duration = this.props.timer ? this.props.timer : DEFAULT_TIMER;
		this.timer = setInterval(() => {
			this.setState({ width: Math.min(this.state.width + 100/(duration/TIMER_TICK), 100.00) });
		}, TIMER_TICK);
		setTimeout(() => clearInterval(this.timer), duration);
	}

	endTimer() {
		this.setState({ width: 100.00 });
		setTimeout( () => { this.setState({ isVisible: false}); clearInterval(this.timer);}, 600);
	}

	componentWillUnmount() {
		clearInterval(this.timer);
	}

	componentDidMount(){
		if ( this.props.isLoading ) this.startTimer();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.isLoading && this.props.isLoading !== prevProps.isLoading) {
			this.startTimer();
		}
		if (!this.props.isLoading && this.state.width < 100.00) {
			this.endTimer();
		}
	}

	render() {
		const {isVisible} = this.state;
		const {isLoading} = this.props;
		if (isLoading || isVisible) return this.renderLoader();
		return this.props.children;

	}

}

export default AppLoader
