/**/
import React, { Component } from 'react'
import s from './ArchiveToolbar.module.css'
import DarkThemedButton from "../ThemeButton/DarkThemeButton";
import exportIcon from "../../assets/exportIcon.svg";
import t from "../../translation/translation";
import {DateRangePicker} from "react-dates";
import moment from "moment";
import calendarIcon from "../../assets/calendarIcon.svg";
import TransparentButton from "../ThemeButton/TransparentButton";
import arrowIconLeft from "../../assets/archiveIconLeft.svg";
import cx from "classnames";
import Media from "react-media";
import {isOutsideRange} from "../../utils/archive";
import YellowButton from "../ThemeButton/YellowButton";
import ExportModal from "../ExportModal/ExportModal";
import report from "../../api/report";
import POSTooltip from "../POSTooltip/POSTooltip";
import Checkbox from "../Checkbox/Checkbox";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import * as qs from "qs";
import cancelIcon from "../../assets/cancelDatepickerIcon.svg";
import openIcon from "../../assets/openDatepickerIcon.svg";

const ARCHIVE_EXPORT_START_ID = "ARCHIVE_EXPORT_START_ID";
const ARCHIVE_EXPORT_END_ID = "ARCHIVE_EXPORT_END_ID";

const archiveDateFormats = ['D. MMM YYYY', 'D. MMM YYYY', 'DD.MM.YYYY', 'DD.M.YYYY', 'D.M.YYYY', 'DD.M.YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD'];

class ArchiveToolbar extends Component {

	state = {
		startDate: null,
		endDate: null,
		isOpen: false,
		focusedInput: null,
		exportModalOpen: false,
		winWidth: window.innerWidth,
		dateFormat: archiveDateFormats[0],
		successful: false
	};

	updateQuery () {
		const {successful} = this.state;
		const begin = this.state.startDate ? this.state.startDate.format('YYYY-MM-DD') : "";
		const end = this.state.endDate ? this.state.endDate.format('YYYY-MM-DD'): "";
		const newQuery = {...qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true }), successful, begin, end }
		if (!successful) {
			delete newQuery.successful
		}
		this.props.history.push({ search: qs.stringify(newQuery)});
	};

	componentDidMount() {
		this.updateDimensions();
		window.addEventListener("resize", this.updateDimensions);
	}
	componentWillUnmount(){
		window.removeEventListener("resize", this.updateDimensions);
	}

	updateDimensions = () => {
		this.setState({
			winWidth: window.innerWidth
		})
	};

	handleRangeOpen = () => (focusedInput) => {
		this.setState({focusedInput});
		if (!this.state.isOpen) {
			this.setState({ isOpen: true }, () => {
				this.props.toggleDateFilter(); this.props.tooltip.nextStep()();
			});
		}
	};

	handleRangeClose = () => () => {
		this.setState({ isOpen: false });
		this.props.toggleDateFilter();
	};

	handleRangeReset = () => () => {
		this.setState({ startDate: null, endDate: null }, () => {this.updateQuery(); this.props.loadData(); });
	};

	toggleExportModalOpen = () => () => {
		this.handleRangeOpen()("startDate");
		if(!this.state.startDate || !this.state.endDate) {
			this.setState({ error: t("ARCHIVE.export.error" )});
			return;
		}
		this.props.tooltip.nextStep()();
		this.setState({ exportModalOpen: !this.state.exportModalOpen });
	};

	toggleSelectSuccessful = () => () => {
		this.setState({ successful: !this.state.successful }, this.updateQuery);

	};

	// Ugly hackaround demanded by the product owner, can't do it cleaner
	handleDateSelect = () => async ({startDate, endDate}) => {
		const startDateInput = document.getElementById(ARCHIVE_EXPORT_START_ID).value;
		const endDateInput = document.getElementById(ARCHIVE_EXPORT_END_ID).value;
		const startDateObject = moment(startDateInput, this.state.dateFormat, true);
		const endDateObject = moment(endDateInput, this.state.dateFormat, true);
		if (!moment.isMoment(startDate) && !startDateObject.isValid()) {
			let dateFormat = this.state.dateFormat;
			archiveDateFormats.some((format) => {
				if (moment(startDateInput, format, true).isValid()) {
					dateFormat = format;
					return true;
				}
			});
			if (endDateObject.isValid()) archiveDateFormats.some((format) => {
				if (moment(endDateInput, format, true).isValid()) {
					dateFormat = format;
					return true;
				}
			});
			if (dateFormat !== this.state.dateFormat) {
				await this.setState({ startDate: moment(startDateInput, dateFormat), dateFormat }, this.updateQuery );
			}
		} else {
			this.setState({ startDate, endDate, error: undefined }, this.updateQuery);
		}

	};

	handleExport = (selectedOption) => () => {
		window.open(report.getExportReportsUrl(selectedOption, this.state.startDate, this.state.endDate, this.state.successful), '_blank');
		this.setState({ exportModalOpen: false });
	};

	handleLastMonthSelect = () => () => {
		this.setState({ startDate: moment().subtract(1, 'months'), endDate: moment()}, this.updateQuery);
		this.handleRangeOpen()("startDate");

	};

	handleFilter = () => () => {
		this.updateQuery();
		this.props.loadData();
	};

	render() {
		const {tooltip, loadData} = this.props;
		const {isOpen, endDate, startDate, dateFormat, focusedInput, exportModalOpen, winWidth, error} = this.state;
		const isMobile = winWidth < 980;
		return <div className={s.toolbar}>
			<div className={cx(s.toolWrapper)}>
				<div className={s.dateSelectWrapper}>
					<div className={s.closeButton}>
						<TransparentButton onClick={startDate ? this.handleRangeReset(): () => this.handleRangeOpen()("startDate")}>
							<img src={startDate ? cancelIcon : openIcon} />
						</TransparentButton>

					</div>
					<DateRangePicker
						startDate={startDate}
						displayFormat={dateFormat}
						keepOpenOnDateSelect={true}
						startDateId={ARCHIVE_EXPORT_START_ID}
						endDateId={ARCHIVE_EXPORT_END_ID}
						endDate={endDate}
						onDatesChange={this.handleDateSelect()}
						focusedInput={focusedInput}
						onClose={this.handleRangeClose()}
						customInputIcon={<div className={"d-flex"}><img src={calendarIcon} /></div>}
						hideKeyboardShortcutsPanel={true}
						isOutsideRange={isOutsideRange}
						startDatePlaceholderText={t("ARCHIVE.export.startDate")}
						endDatePlaceholderText={t("ARCHIVE.export.endDate")}
						transitionDuration={0}
						minimumNights={0}
						numberOfMonths={isMobile ? 1 : 2}
						customArrowIcon={<div className={"divider"}></div>}
						orientation={isMobile ? "vertical" : "horizontal"}
						verticalHeight={500}
						onFocusChange={this.handleRangeOpen()}
					/>
				</div>
				{error && <div className={s.error}>{error}</div>}
				<TransparentButton onClick={this.handleLastMonthSelect()} className={s.monthButton} outline={true}>
					{t("ARCHIVE.export.lastMonth")}
				</TransparentButton>
				<div className={s.successfulPayments}>
					<Checkbox checked={this.state.successful} onClick={this.toggleSelectSuccessful()} />
					<div className={s.successfulPaymentsLabel}>{t("ARCHIVE.export.successfulPayments")}</div>
				</div>

				<POSTooltip tooltip={tooltip} tooltipStep={0} />
			</div>
			<div className={"position-relative"}>
				<DarkThemedButton onClick={this.toggleExportModalOpen()} className={s.exportButton}>
					<img src={exportIcon} className={s.exportIcon} />
					<div className={s.exportLabel}> {t('ARCHIVE.exportLabel')}</div>
				</DarkThemedButton>

			</div>
			<YellowButton onClick={this.handleFilter()} onMouseDown={this.handleFilter()} onTouchStart={this.handleFilter()} className={cx(s.dateExport, {"d-none": !isOpen})}>
				{t("ARCHIVE.export.showResults")}
			</YellowButton>
			<POSTooltip tooltip={tooltip} tooltipStep={1} />
			<ExportModal onExport={this.handleExport} startDate={startDate} endDate={endDate} isOpen={exportModalOpen} toggle={this.toggleExportModalOpen()}/>
		</div>

	}

}
function mapState (state) {
	return {
	}
}

function mapActions(dispatch) {
	return bindActionCreators({}, dispatch);
}



export default withRouter(connect(mapState, mapActions)(ArchiveToolbar));
