import React from 'react';
import s from "./KeyboardButton.module.css";
import {Button} from "reactstrap";

// Set prop types
type KeyboardButtonProps = {};

function KeyboardButton(props) {
	return (
		<Button onClick={() => props.onClick(props.data.value)} className={s.wrapper}>
			{props.data.icon ?
				<img src={props.data.icon}/>
				:
				<div>{props.data.value}</div>
			}
		</Button>
	);
}

export default KeyboardButton;
