import {fulfilled, fulfilledState, initState, pending, pendingState, rejected, rejectedState} from "./util";
import status from "../api/status";

export const GET_STATUSES = "GET_STATUSES";

export default function (state = initState, action) {
    switch (action.type) {
        case pending(GET_STATUSES):
            return pendingState(state);
        case fulfilled(GET_STATUSES):
            return fulfilledState(state, action.payload.data);
        case rejected(GET_STATUSES):
            return rejectedState(state, action.payload);
        default:
            return state;
    }
}

export const getStatuses = () => ({
    type: GET_STATUSES,
    payload: status.getStatuses()
});
