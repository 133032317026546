import { getSafeDeep } from "./state";
import React from "react";
import moment from "moment";
import { STATUS_NAME_MAP } from "./transaction";
import t, { getCurrencyId, getLanguage, getCurrency } from "../translation/translation";
import TransparentButton from "../components/ThemeButton/TransparentButton";
import printIcon from "../assets/printIcon.svg";
import undoIcon from "../assets/undoArrow.svg";
import s from "../views/Archive/Archive.module.css";
import { revertAccounts } from "../config";

function getDecimalPlaces(currencyId, context) {
    const currency = context.currencies.find(item => item.id === currencyId);
    return getSafeDeep(currency, "decimal_places", 2);
}

export function renderDateTime(row, context, isPrint = false) {
    const timestamp = moment(getSafeDeep(row, "last_updated"));
    const time = timestamp.format("HH:mm");
    const date = timestamp.format("D. MMM");
    const year = timestamp.format("YYYY");
    if (isPrint) {
        return <div>{time}, {date} {year}</div>;
    }
    return <div>
        <div>
            {time}
        </div>
        <div>
            {date} <span className={"mobile-hidden"}>{year}</span>
        </div>
    </div>
}

export function renderCombinedAmount(row, context) {

    return <div>
        {renderFiatAmount(row, context)}
        {renderCryptoAmount(row, context)}
    </div>
}

export function renderFiatAmount(row, context) {
    let amount = getSafeDeep(row, "extra.equivalent_value.amount", getSafeDeep(row, "amount"));
    let currencyId = getSafeDeep(row, "extra.equivalent_value.currency_id", 0);
    return <div className={"font-weight-bold"}>
        {amount && getCurrency().parse(amount, 2)} {context.currencies.find(item => item.id === currencyId).pos_display_name}
    </div>
}

export function renderCryptoAmount(row, context) {
    return (<div>
        {/* {getSafeDeep(row, "crypto_amount") ? parseFloat(getSafeDeep(row, "crypto_amount")).toFixed(getDecimalPlaces(row.crypto_currency, context)).replace(".", ",") : ""} {getCurrencyName(row, context)} */}
        {getSafeDeep(row, "crypto_amount") ? getCurrency().parse(getSafeDeep(row, "crypto_amount"), getDecimalPlaces(row.crypto_currency, context)) : ""} {getCurrencyName(row, context)}
    </div>)
}

export function renderReportCryptoAmount(row, context) {
    let decimalPlaces = 8;
    if(row && context) decimalPlaces = context.currencies.find((item) => item.id == row.crypto_currency).decimal_places;
    // return <div>{getSafeDeep(row, "crypto_amount") ? parseFloat(getSafeDeep(row, "crypto_amount")).toFixed(decimalPlaces).replace(".",",") : ""} {getCurrencyName(row, context)}</div>
    return (<div>
        {getSafeDeep(row, "crypto_amount") ? getCurrency().parse(getSafeDeep(row, "crypto_amount"), decimalPlaces) : ""} {getCurrencyName(row, context)}
    </div>)

}
export const renderReportAmount = (row, instance, useSymbol = false) => {
    let decimalPlaces = 2;
    let currency = 0;
    if (instance.props.context.currencies) currency = instance.props.context.currencies.find((item) => item.id == getCurrencyId());
    if (currency) decimalPlaces = currency.decimal_places;
    let amount = currency.id === 0 ? row.amount : row.equivalent_amount;
    // let parsed = parseFloat(amount).toFixed(decimalPlaces);
    let parsed = getCurrency().parse(amount, decimalPlaces);
    // if (useSymbol) return <span>{parsed.replace(".", ",")} </span>
    return <span>{parsed} </span>;
};

export function getCurrencyName(row, context) {
    return getSafeDeep(context.currencies.find((item) => item.id === getSafeDeep(row, "crypto_currency")), "pos_display_name");
}

export function renderReferenceNumber(row) {
    return <div>{row.reference_number}</div>
}

export function renderStatus(row) {
    const statusMap = {
        1: 'Gray',
        2: 'Green',
        3: 'Blue',
        4: 'Red',
        5: 'Red',
        6: 'Red',
        7: 'Orange',
        8: 'Red',
        9: 'Red'
    };

    const buttonClass = `status${statusMap[row.status]}`;


    return <div className={`statusButton ${buttonClass}`}>{getSafeDeep(getLanguage().PAYMENT.paymentStatus, STATUS_NAME_MAP[row.status])}</div>
}

export function isOutsideRange(date) {
    return moment(date).startOf('day') > moment();
}

export function renderPrintButton(row) {

    return <TransparentButton>
        <img src={printIcon} />
    </TransparentButton>
}

export function renderRevertButton(row, context) {
    if (row.status !== 2) return <div></div>;
    return <TransparentButton onClick={context.revert}>
        <img className={s.revertIcon} src={undoIcon} />
    </TransparentButton>
}

export function getRevertPermission(user) {
    return revertAccounts.find(account => account === user.email);
}

// Quick fix
export function checkBooleanPermission(value) {
    return value;
}
