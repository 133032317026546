import React from 'react';
import s from "./QRCodeControl.module.css";
import * as cx from "classnames";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {PAYMENT_DURATION} from "../../config";
import {secondsToMinutes} from "../../utils/transformations";

import t from "../../translation/translation";
import {getSafeDeep} from "../../utils/state";
import LightThemeButton from "../ThemeButton/LightThemeButton";
import Loader from "../Loader/Loader";
import YellowButton from "../ThemeButton/YellowButton";
import GrayButton from "../ThemeButton/GrayButton";
import AmountWidget from "../AmountWidget/AmountWidget";
import moment from "moment";

// Set prop types
type QRCodeControlProps = {};

class QRCodeControl extends React.Component<QRCodeControlProps> {


	render() {
		const {paymentTimestamp, timer, step, isLoading, handlePaymentCancellation, payment, amount, euroAmount, status} = this.props;
		const secondsPassed = moment().diff(paymentTimestamp, 'seconds');
		const percentage = secondsPassed / PAYMENT_DURATION * 100;
		if (step !== 1) return<></>;

		return (

			<div className={cx(s.wrapper, {"card-shadow": step === 1})}>
				<Loader isLoading={isLoading}>
					<div className={s.container}>

						<div className={s.status}>{t('PAYMENT.qrCodeReady')}</div>
						<div className={s.progressLineWrapper}>
							<div style={{width: `${percentage}%`}} className={s.progressLine}>

							</div>
						</div>
						<div className={s.timer}>
							{secondsToMinutes(PAYMENT_DURATION - secondsPassed)}
						</div>
						<div className={s.QRWrapper}>
							<img src={`data:image/jpeg;base64,${getSafeDeep(payment, "image")}`} />
						</div>
						<AmountWidget amount={amount} status={status} euroAmount={euroAmount} />
					</div>

					<div className={s.buttonWrapper}>
						<GrayButton onClick={(e) => handlePaymentCancellation(e)} outline className={s.button}>
							{t('PAYMENT.cancelPaymentButton')}
						</GrayButton>
					</div>
				</Loader>
			</div>

		);
	};


}

// Set default props
QRCodeControl.defaultProps = {
	timer: 93
};

export default QRCodeControl;
