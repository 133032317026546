/**/
import React, {Component} from 'react'
import s from './Payment.module.css'
import Container from "reactstrap/es/Container";
import ProgressStep from "../../components/ProgressStep/ProgressStep";
import {getSafeDeep} from "../../utils/state";
import {getCurrencyId} from "../../translation/translation";
import {bindActionCreators} from "redux";
import {getCurrencies} from "../../redux/currency";
import {getConversionRate} from "../../redux/conversionRate";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {PaymentConsumer, PaymentProvider} from "./PaymentContext";
import PaymentInput from "../../components/PaymentInput/PaymentInput";
import PaymentKeyboard from "../../components/PaymentKeyboard/PaymentKeyboard";
import QRCodeControl from "../../components/QRCodeControl/QRCodeControl";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import PaymentStatus from "../../components/PaymentStatus/PaymentStatus";
import CurrencyNotice from "../../components/CurrencyNotice/CurrencyNotice";
import {TooltipConsumer, TooltipProvider} from "../../context/TooltipContext";
import {setNavigationVisible} from "../../redux/navigation";


const paymentTooltipSteps = [
    {
        key: "TOOLTIPS.PAYMENT",
        position: "left",
        mobilePosition: "bottom",
        style: {
            top: 200,
            transform: `translateX(calc(-100% - 14px))`
        },
        mobileStyle: {
            top: 75
        },
        arrow: {
            top: 45
        },
        mobileArrow: {
            top: 0,
            left: 71
        }
    },
    {
        key: "TOOLTIPS.PAYMENT",
        position: "left",
        mobilePosition: "bottom",
        style: {
            top: -70,
            transform: `translateX(calc(-100% - 14px))`
        },
        arrow: {
            top: 80
        },
        mobileStyle: {
            top: 80,
            transform: `translateX(-50%)`,
            left: `50%`
        },
        mobileArrow: {
            left: `50%`,
            top: -14,
            transform: `translateX(-50%)`
        }

    }
];

class Payment extends Component {

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        await this.props.getConversionRate(getCurrencyId());
    }

    render() {
        const {navigationVisible, setNavigationVisible} = this.props;
        return (
            <>

                <PaymentProvider navigationVisible={navigationVisible}
                                 setNavigationVisible={setNavigationVisible}
                                 conversionRate={this.props.conversionRate}>
                    <TooltipProvider steps={paymentTooltipSteps}>
                        <PaymentConsumer>
                            {({step}) => <ProgressBar step={step}/>}
                        </PaymentConsumer>
                        <Container className={"payment-container"}>
                                <PaymentConsumer>
                                    {({step}) => <ProgressStep step={step}/>}
                                </PaymentConsumer>

                                <div className={s.paymentWrapper}>
                                    <PaymentConsumer>
                                        {paymentProps =>
                                            <TooltipConsumer>
                                                {props => <PaymentInput {...{...paymentProps, tooltip: props}} />}
                                            </TooltipConsumer>
                                        }
                                    </PaymentConsumer>

                                    <PaymentConsumer>
                                        {paymentProps =>
                                            <TooltipConsumer>
                                                {props => <PaymentKeyboard {...{...paymentProps, tooltip: props}} />}
                                            </TooltipConsumer>
                                        }
                                    </PaymentConsumer>

                                    <PaymentConsumer>
                                        {props => <QRCodeControl {...props} />}

                                    </PaymentConsumer>

                                    <PaymentConsumer>
                                        {props => <PaymentStatus {...props} />}

                                    </PaymentConsumer>
                                </div>
                                {false && <PaymentConsumer>
                                    {props => <CurrencyNotice {...props} />}
                                </PaymentConsumer>}

                        </Container>
                    </TooltipProvider>
                </PaymentProvider>
            </>
        )

    }

}


function mapState(state) {
    return {
        currencies: state.currencies.data,
        rate: state.conversionRate,
        navigationVisible: state.navigationVisible,
        conversionRate: getSafeDeep(state, `conversionRate.data.trader_value`)
    };
}

function mapActions(dispatch) {
    return bindActionCreators({getCurrencies, getConversionRate, setNavigationVisible}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(Payment));
