
import {apiClient} from './util';
import {AxiosPromise} from 'axios';


export default {
    getUser: (): AxiosPromise => apiClient.get("/auth"),
    login: (username: string, password: string): AxiosPromise => apiClient.post("/auth", {username, password}),
    logout: (): AxiosPromise => apiClient.delete("/auth"),
    setReason: (reason: string): AxiosPromise => apiClient.post("/admin/reason", {reason})
};
