
import {apiClient} from './util';
import {AxiosPromise} from 'axios';
import qs from 'qs';
import {RESULTS_PER_PAGE} from "../views/Archive/Archive";


export default {
    getTransactions: (page, begin = undefined, end = undefined, successful = false): AxiosPromise => {
        let params = {
            offset: 0,
            limit: RESULTS_PER_PAGE
        };
        if (begin && end) {
            params.begin = begin;
            params.end = end;
        }
        params.successful = successful !== undefined ? successful : false;
        params.offset = parseInt(page) * RESULTS_PER_PAGE;
        const paramsString = qs.stringify(params);
        return apiClient.get("/payment/crypto/pos?" + paramsString);
    }
};
