/**/
import React, {Component} from 'react'
import s from './AmountWidget.module.css'
import t, {getCurrency, getCurrencyId} from "../../translation/translation";
import cx from "classnames";

class AmountWidget extends Component {
    render() {
        let cssClass = "";
        let currency = getCurrency();
        if (this.props.status) {
            cssClass = this.props.status.cssClass;
        }
        const {euroAmount, amount} = this.props;
        let symbolFont = 34 - (currency.display_symbol.length - 1) * 11;
        if (symbolFont < 12) symbolFont = 12;
        return <div className={s.wrapper}>
            <div className={s.label}>
                {t('PAYMENT.amount')}
            </div>
            <div className={s.container}>
                <div className={cx(s.currency, cssClass)} style={{fontSize: symbolFont}}>
                    {currency.display_symbol}
                </div>
                <div className={cx(s.amount, cssClass)}>
                    {amount}
                </div>
            </div>
            {false && <div className={cx(s.euroAmount, {"invisible": getCurrencyId() === 0})}>
                € {euroAmount} *
            </div>}
        </div>

    }

}

export default AmountWidget;
