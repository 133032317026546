import React from 'react';
import {Button} from "reactstrap";
import * as classnames from "classnames";
import css from "./ThemedButton.module.css";


// Set prop types
type ThemedButtonProps = {};

class YellowButton extends React.Component<ThemedButtonProps> {

    render() {
        let {color, lg} = this.props;

        return (
            <Button {...this.props}
                    outline={this.props.outline}
                    className={classnames(css.boxButton, {[css.largeButton]: lg}, css.yellowButton, this.props.className)}
                    onClick={this.props.onClick}>
                { this.props.children }
            </Button>
        );
    };

}

// Set default props
YellowButton.defaultProps = {};

export default YellowButton;
