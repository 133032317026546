import React from 'react';
import Sidebar from "./components/Sidebar/Sidebar";
import {Redirect, Route, Switch, withRouter} from "react-router";
import {routes} from "./utils/routes";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import {getCurrencies, setCurrency} from "./redux/currency";
import ErrorHandler from "./components/ErrorHandler/ErrorHandler";
import {getConversionRate} from "./redux/conversionRate";
import auth from "./api/auth";
import {clearUser, getUser} from "./redux/user";
import AppLoader from "./components/AppLoader/AppLoader";
import {getSourceCurrency, getSourceLanguage} from "./translation/translation";
import {setLanguage} from "./redux/language";
import {ELIPAY_LOCAL_STORAGE} from "./config";
import {getSupportedLanguages} from "./redux/supportedLanguage";
import {getRevertPermission} from "./redux/revertPermission";


class AppRouter extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isMaintenanceMode: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.auth != this.props.auth && this.props.auth) {
            this.props.setLanguage(getSourceLanguage(this.props.auth));
            this.props.setCurrency(getSourceCurrency(this.props.auth));
            if (!this.state.isMaintenanceMode && localStorage.getItem(`${ELIPAY_LOCAL_STORAGE}/maintenanceMode`) === 'true') {
                this.setState({ isMaintenanceMode: true });
            }
        }
    }

    async componentDidMount() {
        await this.props.getCurrencies();
        await this.props.getSupportedLanguages();
        if (!this.state.isMaintenanceMode && localStorage.getItem(`${ELIPAY_LOCAL_STORAGE}/maintenanceMode`) === 'true') {
            this.setState({ isMaintenanceMode: true });
        }
        try {

            const {data} = await auth.getUser();
            this.props.getRevertPermission();
            this.props.setLanguage(getSourceLanguage(data));
            this.props.setCurrency(getSourceCurrency(data));
            await this.props.getConversionRate();
            await this.props.getUser();

        } catch (e) {
            this.props.clearUser();
        }
        this.setState({ isLoading: false });
    }

    renderRoutes() {
        const auth = this.props.auth;
        const maintenanceMode = this.state.isMaintenanceMode;
        if (maintenanceMode) {
            return <>
                <Route key={routes.maintenance.path} exact path={routes.maintenance.path}
                component={routes.maintenance.component}/>
                <Redirect to={routes.maintenance.path} key={'redirect'} />
            </>
        }
        let routing = [];
        if (auth) {
            routing = Object.values(routes).filter(route => route.authenticated).map(route => {
                return <Route key={route.path} exact path={route.path} component={route.component} />
            });
            routing.push(<Redirect key={`${routes.payment.path}redirect`} to={routes.payment.path}/>);
        } else {
            routing = Object.values(routes).filter(route => !route.authenticated).map(route => {
                return <Route key={route.path} exact path={route.path} component={route.component} />
            });
            routing.push(<Redirect key={`${routes.login.path}redirect`} to={routes.login.path}/>);
        }
        return routing;
    }

    renderContent() {
        const {isLoading} = this.state;
        return <AppLoader isInitial={false} isLoading={isLoading}>
            <Switch>
                {this.renderRoutes()}
            </Switch>
        </AppLoader>
    }

    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', position: 'relative'}}>
                <Sidebar/>
                <Header />
                    <ErrorHandler>
                        {this.renderContent()}
                    </ErrorHandler>
                <Footer />
            </div>
        );
    };

}
function mapState(state) {
    return {
        auth: state.user.data
    }
}

function mapActions(dispatch) {
    return bindActionCreators({getCurrencies, getSupportedLanguages, setCurrency, getUser, setLanguage, getConversionRate, clearUser, getRevertPermission}, dispatch);
}

export default withRouter(connect(mapState,mapActions)(AppRouter));
