import activePaymentIcon from "../assets/newPaymentActive.svg";
import paymentIcon from "../assets/newPaymentInactive.svg";
import billingIcon from "../assets/billingInactive.svg";
import activeBillingIcon from "../assets/billingActive.svg";
import archiveIcon from "../assets/archiveInactive.svg";
import activeArchiveIcon from "../assets/archiveActive.svg";
import faqIcon from "../assets/faqInactive.svg";
import activeFaqIcon from "../assets/faq-iocn.svg";
import React from 'react';
import Payment from "../views/Payment/Payment";
import Help from "../views/Help/Help";
import {ROUTE_PREFIX} from "../config";
import Archive from "../views/Archive/Archive";
import Login from "../views/Login/Login";
import Report from "../views/Report/Report";
import Maintenance from "../views/Maintenance/Maintenance";


export const routes = {
    payment:{
        path: `${ROUTE_PREFIX}/payment/`,
        exact: true,
        authenticated: true,
        label: "Novo plačilo",
        key: "newPayment",
        icon: paymentIcon,
        component: Payment,
        activeIcon: activePaymentIcon
    },
    transactions:{
        path: `${ROUTE_PREFIX}/transactions/`,
        exact: true,
        authenticated: true,
        label: "Arhiv plačil",
        key: "archive",
        icon: archiveIcon,
        activeIcon: activeArchiveIcon,
        component: Archive
    },
    report:{
        path: `${ROUTE_PREFIX}/report/`,
        label: "Obračun",
        key: "billing",
        icon: billingIcon,
        activeIcon: activeBillingIcon,
        exact: true,
        authenticated: true,
        component: Report
    },
    login:{
        path: `${ROUTE_PREFIX}/login/:lang(en|sl|hr|tr|es|ja)?`,
        regex: `${ROUTE_PREFIX}/login/:lang(en|sl|hr|tr|es|ja)?`,
        component: Login,
        authenticated: false,
        exact: true,
        key: "login"
    },
    help:{path: `${ROUTE_PREFIX}/help/`,
        label: "FAQ",
        icon: faqIcon,
        key: "faq",
        activeIcon: activeFaqIcon,
        exact: true,
        authenticated: true,
        component: Help
    },
    maintenance: {
        path: `${ROUTE_PREFIX}/maintenance`,
        exact: true,
        component: Maintenance,
        maintenance: true
    },
    redirect:{path: "/pos/redirect/"},
    kycWrongDevice: {path: "/pos/kyc/wrong-device/"},
    registrationWrongDevice: {path: "/pos/confirm-registration/wrong-device/"},
    passwordWrongDevice: {path: "/pos/reset-password/wrong-device/"},
    changePasswordConfirm: {path: "/pos/change-password/confirm/"},
    changePasswordLocked: {path: "/pos/change-password/locked/"},
    changePasswordMisclick: {path: "/pos/change-password/misclick/"},
    unsubscribeSuccessfull: {path: "/pos/unsubscribe/"}
};
