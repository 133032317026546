import {fulfilled, fulfilledState, initState, pending, pendingState, rejected, rejectedState} from "./util";
import currency from "../api/currency";
import {CURRENCY_STORAGE_KEY} from "../translation/translation";

export const GET_CURRENCIES = "GET_CURRENCIES";
export const SET_CURRENCY = "SET_CURRENCY";

export default function (state = initState, action) {
    switch (action.type) {
        case pending(GET_CURRENCIES):
            return pendingState(state);
        case fulfilled(GET_CURRENCIES):
            return fulfilledState(state, action.payload.data);
        case rejected(GET_CURRENCIES):
            return rejectedState(state, action.payload);
        case SET_CURRENCY:
            localStorage.setItem(CURRENCY_STORAGE_KEY, JSON.stringify(action.payload));
        default:
            return state;
    }
}

export const getCurrencies = () => ({
    type: GET_CURRENCIES,
    payload: currency.getCurrencies()
});

export const setCurrency = (currency) => ({
    type: SET_CURRENCY,
    payload: currency
});
