import {fulfilled, fulfilledState, initState, pending, pendingState, rejected, rejectedState} from "./util";
import language from "../api/language";

//const
export const GET_SUPPORTED_LANGUAGES = "GET_SUPPORTED_LANGUAGES";

export default function (state = initState, action) {
    switch (action.type) {
        case pending(GET_SUPPORTED_LANGUAGES):
            return pendingState(state);
        case fulfilled(GET_SUPPORTED_LANGUAGES):
            return fulfilledState(state, action.payload.data);
        case rejected(GET_SUPPORTED_LANGUAGES):
            return rejectedState(state, action.payload);
        default:
            return state;
    }
}

export const getSupportedLanguages = () => ({
    type: GET_SUPPORTED_LANGUAGES,
    payload: language.getSupportedLanguageList()
});
