import React from "react";
import s from "./PaymentInput.module.css";
import t, { getThousands, getFraction, getCurrency, isCurrencyEuro } from "../../translation/translation";
import { routes } from "../../utils/routes";
import cx from "classnames";
import DarkThemeButton from "../ThemeButton/DarkThemeButton";
import lightBulbIcon from "../../assets/lightBulb.svg";
import { Input } from "reactstrap";
import moment from "moment";
import ReferenceNumberInput from "../ReferenceNumberInput/ReferenceNumberInput";
import LightThemeButton from "../ThemeButton/LightThemeButton";
import ReactSVG from "react-svg";
import YellowButton from "../ThemeButton/YellowButton";
import POSTooltip from "../POSTooltip/POSTooltip";
import { getDecimalSeparator } from "../../utils/transformations";

class PaymentInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth
        };
        this.inputReference = React.createRef();
    }

    updateDimensions = () => {
        this.setState({
            windowWidth: window.innerWidth
        })
    };

    componentDidMount() {
        if (this.state.windowWidth < 980.0 || this.state.windowWidth > 1400.0) {
            this.inputReference.focus();
        }
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.step === 0 && prevProps.step !== this.props.step) {
            if (this.state.windowWidth < 980.0 || this.state.windowWidth > 1400.0) {
                this.inputReference.focus();
            }

        }
    }

    renderErrorField() {
        const isEuro = isCurrencyEuro();
        const { error, euroAmount } = this.props;
        if (error) return <div className={s.error}>{error}</div>;
        if (isEuro) return <div className="invisible">I</div>;
        // if (!euroAmount) return <div className={cx(s.euroValue, "invisible")}>I</div>;
        return <div className={cx(s.euroValue, "invisible")}>I</div>;
        return <div className={s.euroValue}>
            € {euroAmount ? euroAmount : "0,00"} *
        </div>;
    }

    handleKeyPress = () => (event) => {
        if (event.key === "Enterdd") {
            this.props.handlePaymentSubmit();
        }
    };

    handleAmountChange = () => (e) => {
        this.props.tooltip.nextStep()();
        this.props.handleAmountChange(e);
    };

    handleAmountChangeNumber = () => (e) => {
        this.props.tooltip.nextStep()();
        this.props.handleAmountChangeNumber(e);
    };

    handlePaymentSubmit = () => (e) => {
        e.preventDefault();
        this.props.tooltip.nextStep()();
        this.props.handlePaymentSubmit();
    };

    render() {

        const {
            amount, step, hideReferenceNumber, handleAmountChangeNumber,
            handleAmountChange, append, prepend, referenceNumber, handleReferenceNumberChange, tooltip
        } = this.props;
        const { windowWidth } = this.state;
        const disabled = step > 0;
        let symbolFont = 38 - (getCurrency().display_symbol.length - 1) * 11.5;
        if (symbolFont < 15) symbolFont = 15;
        let cssClass = undefined;
        if (step === 2) {
            cssClass = this.props.status.cssClass;
        }

        const fraction = getFraction();
        let value = amount;

        // use dot because of JS
        if (value == '') {
            //don't do anything if empty
        } else if (value.endsWith('.')) {
            //allow to end with fraction
            value = getCurrency().parse(value) + fraction
        } else if (value.endsWith('0') && value.includes('.')) {
            //allow to end with 0 after fraction
            const fracted = value.split('.')
            value = getCurrency().parse(fracted[0]) + fraction + fracted[1]
        } else {
            value = getCurrency().parse(value)
        }

        return (
            <div
                className={cx(s.wrapper, { [s.disabled]: disabled }, { "mobile-hidden": step > 0 }, { "card-shadow": step === 0 })}>
                <form onSubmit={this.handlePaymentSubmit()}>
                    <div className={s.padding}>

                        {prepend && prepend}
                        <div className={cx(s.amount, "amount")}>
                            <div className={"between-centered"}>
                                <div className={cx(s.label, { [s.regular]: step > 0 })}>
                                    {t('PAYMENT.amount')}
                                </div>
                                <LightThemeButton onClick={tooltip.openTooltip()} active={tooltip.isOpen}
                                    className={cx(s.helpButton, { ["invisible"]: step > 0 })}>
                                    <ReactSVG src={lightBulbIcon} className={s.lightBulb} />
                                    <span className={s.helpLabel}>{t("HEADER.help")}</span>

                                </LightThemeButton>
                            </div>

                            <hr className={s.divider} />
                            <div className={cx(s.inputWrapper, cssClass)}>
                                <div style={{ fontSize: `${symbolFont}px` }}
                                    className={cx(s.currency)}>{getCurrency().display_symbol}</div>
                                {windowWidth > 980.0 ?
                                    <input onKeyPress={this.handleKeyPress()}
                                        ref={c => (this.inputReference = c)}
                                        required disabled={disabled}
                                        className={cx(s.input, cssClass)}
                                        value={value}
                                        onFocus={() => {
                                            if (windowWidth <= 1400) this.inputReference.blur()
                                        }}
                                        onChange={this.handleAmountChange()}
                                        placeholder={getCurrency().parse(0, 2)}
                                        name="amount"
                                        type="text" />
                                    :
                                    <input onKeyDown={(e) => {
                                        this.handleKeyPress(e)
                                    }}
                                        ref={c => (this.inputReference = c)}
                                        required disabled={disabled}
                                        className={cx(s.input, cssClass)}
                                        value={value}
                                        inputMode="decimal"
                                        step="any"
                                        // step={"0.01"}
                                        // min={"0.01"}
                                        placeholder={getCurrency().parse(0, 2)}
                                        onChange={this.handleAmountChange()}
                                        name="amount"
                                        type="text" />
                                }
                                <POSTooltip hideOn={980} tooltip={tooltip} tooltipStep={0} />
                            </div>
                            {this.renderErrorField()}
                        </div>
                        {!hideReferenceNumber &&
                            <ReferenceNumberInput
                                disabled={disabled}
                                referenceNumber={referenceNumber}
                                handleReferenceNumberChange={handleReferenceNumberChange} />
                        }

                        {append && append}
                    </div>
                    <div className={"position-relative"}>
                        <YellowButton className={cx("mobile-only", s.submitButton)} type="submit">
                            {t('PAYMENT.createPaymentButton')}
                        </YellowButton>
                        <POSTooltip hideOn={980} tooltip={tooltip} tooltipStep={1} />
                    </div>
                </form>
            </div>

        );
    };

}


export default PaymentInput;
