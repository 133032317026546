import ReactDom from "react-dom";
import React from "react";
import axios from 'axios'

export async function print(Component){

    //check if frame already exists
    let printFrame = window.frames["printFrame"];

    // if frame does not exist create new one
    if(!printFrame){
        let frame = document.createElement('iframe');
        frame.setAttribute("id", "printFrame");
        document.body.appendChild(frame);
        printFrame = window.frames["printFrame"];
    }
    //create styles
    const styles = [];
    const { data: bootstrap } = await axios.get('https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css')
    styles.push(`<style>${bootstrap}</style>`);
    styles.push(`<style>${archiveStyles}</style>`);

    //append styles
    printFrame.contentDocument.head.innerHTML = styles.join('\n');

    //render component in iframe and print it
    ReactDom.render(<Component />, printFrame.contentDocument.body);
    printFrame.contentWindow.print();
}

const archiveStyles = `
* {
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    color-adjust: exact !important;  /*Firefox*/
}

@page { 
    margin: 0cm !important;
}

body, html{
    margin: 0 !important;
    padding: 0 !important;
}
`