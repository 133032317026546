import React from 'react';
import { MAX_EURO_PAYMENT, MIN_EURO_PAYMENT, PAYMENT_DURATION } from "../../config";
import {
    commaToDot,
    formatNumberPaymentSafe,
    isProperlyFormatted,
} from "../../utils/transformations";
import paymentApi from "../../api/payment";
import t, { getFraction, getCurrency, getCurrencyId, isCurrencyEuro, getThousands } from "../../translation/translation";
import { paymentStatusTypes } from "../../utils/paymentStatus";
import { getSafeDeep } from "../../utils/state";
import moment from "moment";

export const PaymentContext = React.createContext('payment');

const initState = {
    step: 0,
    amount: "",
    referenceNumber: "",
    euroAmount: "",
    isLoading: false,
    status: undefined,
    submitButtonDisabled: false,
    timer: PAYMENT_DURATION
};

export class PaymentProvider extends React.Component {
    state = { ...initState };

    requestLock = false;

    timestamp = null;

    componentDidUpdate(prevProps, prevState) {
        if (this.state.step !== prevState.step) {
            if (this.state.step === 0) {
                this.props.setNavigationVisible(true);
            } else {
                this.props.setNavigationVisible(false);
            }
        }
    }

    componentWillUnmount() {
        this.stopCountdown()();
        this.props.setNavigationVisible(true);
    }

    handleAmountChange = () => (e) => {
        let newAmount = e.target.value;

        const thousands = getThousands();
        const fraction = getFraction();

        // console.log(`thousands: ${thousands}`)
        // console.log(`fraction: ${fraction}`)
        // console.log(newAmount)

        //allow to input both comma and dot for decimal
        if (newAmount.endsWith(thousands)) {
            newAmount = newAmount.slice(0, newAmount.length - 1) + fraction;
        }
        // remove thousands mark
        if (thousands == ',') {
            newAmount = newAmount.replace(/,/g, '')
        } else if (thousands == '.') {
            newAmount = newAmount.replace(/\./g, '')
        }

        // change decimal mark to .
        if (fraction == ',') {
            newAmount = newAmount.replace(/,/g, '.')
        }

        //if decimal used when everything else is empty
        if (newAmount == '.') {
            newAmount = "0."
        }

        const properFormat = isProperlyFormatted(newAmount)
        if (properFormat) this.setState({
            amount: newAmount,
            euroAmount: (newAmount * this.props.conversionRate).toFixed(2),
            error: undefined
        });
    };

    handleAmountChangeNumber = () => (e) => {

        let newAmount = formatNumberPaymentSafe(e.target.value);
        let euroAmount = (parseFloat(newAmount) * this.props.conversionRate).toFixed(2);
        if (isNaN(euroAmount)) euroAmount = undefined;
        this.setState({
            amount: newAmount,
            euroAmount: euroAmount,
            error: undefined
        })
    };

    handleReferenceNumberChange = () => (e) => {
        this.setState({ referenceNumber: e.target.value });
    };

    handlePaymentCancellation = () => async (e) => {
        const { payment } = this.state;
        this.stopCountdown()();
        await paymentApi.cancelPayment(payment.payment_id);
        this.setState({ ...initState });
    };

    nextStep = () => () => {
        let step = this.state.step + 1;
        if (step > 2) step = 2;
        this.setState({ step });
    };

    handlePaymentReset = () => (e) => {
        this.stopCountdown()();
        this.setState({ ...initState });
    };

    handleButtonClick = () => (e) => {
        let { amount } = this.state;
        let newAmount = "";
        if (e === "delete") {
            if (amount.length > 0) newAmount = amount.substring(0, amount.length - 1);
        } else {
            newAmount = `${amount}${e}`;
        }

        const fraction = getFraction();
        if (fraction == ',') {
            newAmount = newAmount.replace(/\./g, ',')
        }

        let syntheticEvent = {
            target: {
                value: newAmount
            }
        };
        this.handleAmountChange()(syntheticEvent);
    };

    setFailedStatus() {
        this.stopCountdown()();
        this.timestamp = null;
        this.setState({
            step: 2,
            isLoading: false,
            status: paymentStatusTypes["4"],
            submitButtonDisabled: false,
            timer: PAYMENT_DURATION
        })
    }

    getPaymentStatus = async () => {
        const { payment, timer } = this.state;
        if (!payment) return;
        try {
            if (this.requestLock) return;
            this.requestLock = true;
            const { data } = await paymentApi.getPaymentStatus(payment.payment_id);
            this.requestLock = false;
            // if (timer > 0) await this.setState({status: paymentStatusTypes[data.status]}, () => {
            const diff = moment().diff(moment(this.timestamp), 'seconds');
            if (diff < 300.0) await this.setState({ status: paymentStatusTypes[data.status] }, () => {
                if (data.status != 1 && data.status != 3) {
                    this.setState({ step: 2 });
                    this.stopCountdown()();
                }
            });
        } catch (e) {
        }
    };


    startCountdown = () => () => {
        this.countdown = setInterval(async () => {
            const diff = moment().diff(moment(this.timestamp), 'seconds');
            if (diff > 300.0) this.setFailedStatus();
            // if (this.state.timer <= 0) this.setFailedStatus();
            else {
                // this.setState({timer: moment(this.timestamp, 'seconds').subtract(500, 'miliseconds')});
                this.setState({ timer: this.state.timer - 0.5 });
                await this.getPaymentStatus();
            }
        }, 500);
    };

    stopCountdown = () => () => {
        clearInterval(this.countdown);
        this.timestamp = null;
    };

    render() {
        const values = {
            ...this.state,
            handleAmountChange: this.handleAmountChange(),
            handleAmountChangeNumber: this.handleAmountChangeNumber(),
            handleReferenceNumberChange: this.handleReferenceNumberChange(),
            handlePaymentCancellation: this.handlePaymentCancellation(),
            handlePaymentReset: this.handlePaymentReset(),
            handleButtonClick: this.handleButtonClick(),
            nextStep: this.nextStep(),
            handlePaymentSubmit: this.handlePaymentSubmit(),
            paymentTimestamp: this.timestamp
        };
        return (
            <PaymentContext.Provider value={values}>
                {this.props.children}
            </PaymentContext.Provider>
        );
    }

    validatePayment = () => {
        if (this.state.amount === '') throw { errorType: "amountErrorLow" };
        let amountNumber = parseFloat(commaToDot(this.state.amount));
        let minAmount = MIN_EURO_PAYMENT;
        let maxAmount = MAX_EURO_PAYMENT;
        let amountEuroValue = amountNumber;
        if (!isCurrencyEuro()) amountEuroValue *= parseFloat(this.props.conversionRate);
        if (amountEuroValue < minAmount) {
            throw { errorType: "amountErrorLow" };
        }
        if (amountEuroValue > maxAmount) {
            throw { errorType: "amountErrorHigh" };
        }
    };

    handlePaymentSubmit = () => async (e) => {
        const { amount, referenceNumber } = this.state;

        try {
            // Move the tooltip to the next step
            this.nextStep()();

            // Set the loading state
            this.setState({ isLoading: true });

            this.validatePayment();
            let amountNumber = commaToDot(amount);

            // Send the payment request
            let paymentData = undefined;
            if (isCurrencyEuro()) {
                let paymentRequest = await paymentApi.postPayment(amountNumber, 0, referenceNumber);
                paymentData = paymentRequest.data;
            } else {
                const currencyId = getCurrencyId();
                let paymentRequest = await paymentApi.alternatePayment(0, currencyId, referenceNumber, {
                    amount: amountNumber,
                    currency_id: currencyId
                });
                paymentData = paymentRequest.data
                if (!paymentData) throw { errorType: "unknown" }
            }
            this.timestamp = moment().toISOString();

            // Set payment, error and timer data
            await this.setState({
                payment: paymentData,
                timer: PAYMENT_DURATION,
                isLoading: false,
                error: undefined
            });

            // Start the payment countdown
            this.startCountdown()();
        } catch (e) {
            switch (e.errorType) {
                case "amountErrorLow":
                    this.setState({
                        error: t(`PAYMENT.${e.errorType}`),
                        loading: false,
                        step: 0
                    });
                    return;
                case "amountErrorHigh":
                    this.setState({
                        error: t(`PAYMENT.${e.errorType}`),
                        loading: false,
                        step: 0
                    });
                    return;
                case "amountErrorEmpty":
                    this.setState({
                        error: t(`PAYMENT.${e.errorType}`),
                        loading: false,
                        step: 0
                    });
                    return;
                case "unknown":
                    this.setState({
                        error: t(`PAYMENT.${e.errorType}`),
                        loading: false,
                        step: 0
                    })
                default:
                    this.setState({
                        loading: false,
                        step: 0
                    });
                    return;
            }
        }
    };
}


export const PaymentConsumer = PaymentContext.Consumer;
