import React from 'react';
import css from "./help.module.css";
import * as classnames from "classnames";
import {Button, Collapse} from "reactstrap";
import t, {getLanguage} from "../../translation/translation";
import Container from "reactstrap/es/Container";
import arrowClosed from "../../assets/icon_closed.svg";
import arrowOpen from "../../assets/icon_open.svg";

// Set prop types
type HelpProps = {};

class Help extends React.Component<HelpProps> {

    constructor(props) {
        super(props);
        const FAQ = getLanguage().FAQ;
        this.state = {
            FAQ,
            collapsed: FAQ.QA.map(item => false),
        };
    }


    toggle = (id) => () => {
        let collapsed = this.state.collapsed.map((item, index) => {return id === index && item === false;});
        this.setState({collapsed});
    };

    renderCollapsible(question, index) {
        return <div className={css.collapsibleWrapper} key={question.question}>
            <div className={css.collapsible} onClick={this.toggle(index)}>
                <div>
                    {question.question}
                </div>
                <Button className={classnames(css.button, {[css.active]: this.state.collapsed[index]})}
                        onClick={this.toggle(index)}>
                    <img src={this.state.collapsed[index] ? arrowOpen : arrowClosed}/>
                </Button>

            </div>
            <Collapse className={css.answer} isOpen={this.state.collapsed[index]}>
                {question.answer}
            </Collapse>


        </div>
    }

    render() {
        const {FAQ} = this.state;
        return (
            <Container className="pos-container">
                <div className={classnames(css.wrapper)}>
                    <div className={css.title}>
                        { t('FAQ.title') }
                    </div>
                    <div className={classnames(css.QAWrapper)}>
                        {FAQ.QA.map((faQuestion, index) => {
                            return this.renderCollapsible(faQuestion, index);
                        })}
                    </div>
                </div>
            </Container>
        );
    };

}

// Set default props
Help.defaultProps = {};

export default Help;
