import React from 'react';
import css from "./maintenance.module.css";
import maintenanceImage from "../../assets/maintenance.png";
import goCryptoLogo from "../../assets/primaryLogo.svg"
import {bindActionCreators} from "redux";
import {getCurrencies} from "../../redux/currency";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {currentHistory} from "../../store";
import {routes} from "../../utils/routes";
import {ELIPAY_LOCAL_STORAGE} from "../../config";

class Maintenance extends React.Component {

    componentDidMount() {
        if (localStorage.getItem(`${ELIPAY_LOCAL_STORAGE}/maintenanceMode`) === 'false') {
            currentHistory.push(routes.login.path);
        }
    }

    render() {
        return <div className={css.wrapper}>
            <img className={css.maintenance} src={maintenanceImage} />
            <img className={css.logo} src={goCryptoLogo} />
            <div className={css.message}>
                GoCrypto POS is under scheduled
                maintenance, we will be back shortly.
                Thank you for your patience.
            </div>
        </div>
    };

}
function mapState(state) {
    return {
        auth: state.user.data
    }
}

function mapActions(dispatch) {
    return bindActionCreators({getCurrencies}, dispatch);
}

export default withRouter(connect(mapState,mapActions)(Maintenance));
