import React from "react";
import {Container} from "reactstrap";
import spinner from "../../assets/spinner.svg";
import cx from "classnames";


function Loader(props) {
    const {isLoading, isGlobal, propagate} = props;
    if (!propagate && isLoading) {
        if (isGlobal) {
            return <Container className={"loader-app"}>
                <img className={"spinner"} src={spinner} />
            </Container>;
        } else {
            return <div className={cx("loader", props.className)}><img className={"spinner"} src={spinner} /></div>
        }
    }
    return React.Children.map(props.children, child => {
        if (child.type !== "div") return React.cloneElement(child, {
            isLoading: isLoading && propagate
        });
        else {
            return child;
        }
    });
}

export default Loader;
